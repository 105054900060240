import React, { useEffect, useState } from "react";
import { FaCheck, FaExclamationTriangle, FaInfoCircle, FaTimes } from "react-icons/fa";

export default function NotifficationModal({ type = "success", title, message, onClose, duration = 2000 }) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
        const timer = setTimeout(() => {
            setShow(false);
            setTimeout(onClose, 500);
        }, duration);

        return () => clearTimeout(timer);
    }, [duration, onClose]);

    const getModalStyle = () => {
        switch (type) {
            case "success":
                return "bg-green-100 drop-shadow-lg border-green-500 text-green-700";
            case "error":
                return "bg-red-100 drop-shadow-lg border-red-500 text-red-700";
            case "warning":
                return "bg-yellow-100 drop-shadow-lg border-yellow-500 text-yellow-700";
            default:
                return "bg-gray-100 drop-shadow-lg border-gray-500 text-gray-700";
        }
    };

    const iconType = () => {
        switch (type) {
            case "success":
                return <FaCheck className="text-xl text-green-500" />;
            case "error":
                return <FaTimes className="text-xl text-red-500" />;
            case "warning":
                return <FaExclamationTriangle className="text-xl text-yellow-500" />;
            default:
                return <FaInfoCircle className="text-xl text-gray-500" />;
        }
    };

    return (
        <div className="fixed flex left-[50%] top-0 dan translate-x-[-50%] items-start py-5 justify-center z-30" tabIndex={-1}>
            <div
                className={`min-w-[300px] p-4 rounded-lg shadow-lg transform transition-all duration-200 ease-in-out scale-80 opacity-0 ${
                    show ? "scale-100 opacity-100" : "scale-75 opacity-0"
                } ${getModalStyle()} relative`}
            >
                <div className="flex justify-center items-center space-x-4">
                    <div>{iconType()}</div>
                    <p className="text-base">{message}</p>
                    <button className="text-xl font-bold text-gray-500 hover:text-gray-800" onClick={() => setShow(false)}>
                        &times;
                    </button>
                </div>
            </div>
        </div>
    );
}
