import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import { articles } from "../../../Data/data";
import Header from "../../../components/Header";
import Button from "../../../components/Button";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AOS from "aos";

export default function ArticleDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const data = articles.find((article) => article.id === Number(id));

    const handleBack = () => {
        navigate(-1);
    };

    const handleReadMore = (article) => {
        navigate(`/ourInsight/article/${article.id}`, { state: { article } });
    };

    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "Our Insight", path: "/ourInsight" },
        { label: data.title, path: `/ourInsight/article/${id}` },
    ];
    useEffect(() => {
        AOS.init({
            duration: 800,
            once: true,
            offset: 150,
            easing: "ease-in-out",
        });
    }, []);

    return (
        <div className="h-full w-full overflow-hidden">
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="md:h-[6rem] max-md:h-[6rem] xl:px-32 md:px-14 px-10 text-white hover:text-blue-400 "
            />
            <Breadcrumbs breadcrumbs={breadcrumb} />
            <div className="relative flex flex-col w-full items-center xl:px-32  md:py-20 p-5 font-normal bg-[#F6FBFF]">
                {data ? (
                    <div className="w-full text-[#676767] ">
                        <div className="header md:space-y-10 space-y-5 ">
                            <div>
                                <img className="w-full md:h-[400px] h-1/2 object-cover rounded-lg" src={data.imgSrc} alt="" data-aos="fade-down" />
                            </div>
                            <div className="font-normal space-y-5 text bg-[#fefefe] shadow-md drop-shadow-md px-5 py-10 md:rounded-xl">
                                <div>
                                    {data.title && data.title.length > 0 && (
                                        <h1 className="md:text-3xl font-semibold" data-aos="fade-down">
                                            {data.title}
                                        </h1>
                                    )}
                                </div>
                                <div className="text-[#9E9E9E]" data-aos="fade-right">
                                    {data.date && <p className="">{data.date}</p>}
                                    {data.author && <p className="">{data.author}</p>}
                                    {data.timeRead && <p className="">{data.timeRead}</p>}
                                </div>
                                <main className="overflow-hidden">
                                    {data.summary && (
                                        <p className="" data-aos="fade-left">
                                            {data.summary}
                                        </p>
                                    )}
                                </main>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>Service Detail not found.</p>
                )}
                {/* <button
                    className="absolute bottom-10 left-32 flex text-sm justify-center items-center space-x-5 hover:cursor-pointer"
                    onClick={handleBack}
                >
                    <FaArrowLeft />
                    <h1>Back</h1>
                </button> */}
            </div>
            <div className="w-full flex justify-center xl:px-32 px-5 bg-[#F6FBFF]">
                <div className="relative flex space-x-5 md:space-x-5 overflow-x-scroll  w-full scrollbar-hide">
                    {articles.slice(1).map((article, index) => (
                        <div
                            key={index}
                            className="min-w-[300px] w-[400px] shadow-lg rounded-xl hover:cursor-pointer overflow-hidden"
                            onClick={() => handleReadMore(article)}
                            data-aos="flip-right"
                        >
                            <div className="bg-white w-full overflow-hidden">
                                <img
                                    className="object-cover w-full h-1/2 transform transition duration-500 hover:scale-125 inset-0"
                                    src={article.imgSrc}
                                    alt={article.title}
                                />
                            </div>
                            <div className="text-sm p-3 space-y-2 py-5 bg-white border rounded-xl">
                                <div className="flex w-full space-x-2 text-xs text-[#676767] font-light">
                                    <p>{article.date}</p>
                                    {article.timeRead && (
                                        <>
                                            <span>|</span>
                                            <p>{article.timeRead}</p>
                                        </>
                                    )}
                                </div>
                                <p className="font-medium text-[#676767] w-5/6">{article.title}</p>
                                <div>
                                    <Button title="Read more" onClick={() => handleReadMore(article)} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
