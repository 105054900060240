import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import AOS from "aos";

export default function Article({ articles, handleReadMore, visibleArticles }) {
    const getLimitedSummary = (summary, wordLimit = 20) => {
        const joinedSummary = summary.join(" ");
        const words = joinedSummary.split(" ");
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(" ") + "...";
        }
    };

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
            offset: 150,
            easing: "ease-in-out",
        });
    }, []);
    return (
        <div className="text-black space-y-10 overflow-hidden ">
            <div className="space-y-4 md:px-0 px-5">
                <h1 className="font-semibold text-[1.75rem]" data-aos="fade-down">
                    Article
                </h1>
                <p className="text-[#757575] xl:text-xl lg:text-lg md:text-base text-sm font-normal " data-aos="fade-right">
                    We serve best insight for your industries and learn more about asset management solutions.
                </p>
            </div>
            <div className="space-y-10">
                <div
                    className="relative  p-5 shadow-xl bg-white rounded-xl md:flex justify-center md:space-x-10 h-full md:visible hidden hover:cursor-pointer "
                    onClick={() => handleReadMore(articles[0])}
                    data-aos="fade-right"
                >
                    <div className="relative w-1/3 flex justify-center items-center overflow-hidden">
                        <div className="absolute top-7 left-7 text-white border px-5 py-2 rounded-lg bg-white/10 backdrop-blur-sm z-10 hover:bg-white/35 transition ease-in-out font-normal text-lg">
                            <a href="#">Article</a>
                        </div>
                        <img className="w-full h-auto transform transition duration-500 hover:scale-125" src={articles[0].imgSrc} alt="" />
                    </div>
                    <div className="relative flex-1 space-y-2 mx-auto md:text-4xl">
                        <div className="font-normal text-[#9E9E9E] text-lg">
                            {articles[0].date} | {articles[0].timeRead}
                        </div>
                        <div className="xl:text-4xl lg:text-3xl md:text-2xl text-3xl font-semibold text-[#616161]">{articles[0].title}</div>
                        <div className="xl:text-xl lg:text-lg md:text-base text-sm font-normal xl:max-w-[710px] md:max-w-xl text-[#616161]">{getLimitedSummary(articles[0].summary, 49)}</div>
                        <Button title="Read More " className={"flex w-full justify-start px-0 text-[#003478] "} onClick={() => handleReadMore(articles[0])} />
                    </div>
                </div>

                <div className="lg:flex flex justify-center xl:gap-6 lg:gap-x-5 md:gap-x-5 gap-10 w-full flex-wrap text-black ">
                    {articles.slice(1).map((data, index) => (
                        <div
                            key={data.id}
                            className="relative item-card xl:w-[22.8875rem] lg:w-[18.5875rem] md:w-[20.9975rem] w-[19.5875rem] shadow-lg rounded-xl hover:cursor-pointer overflow-hidden"
                            onClick={() => handleReadMore(data)}
                            data-aos="fade-down"
                        >
                            <div className="bg-white w-full overflow-hidden">
                                <div className="absolute top-7 left-7 text-white border px-5 py-2 rounded-lg bg-white/10 backdrop-blur-sm z-10 hover:bg-white/35 transition ease-in-out font-normal text-xs">
                                    <a href="#">Article</a>
                                </div>
                                <img
                                    className="object-cover w-full transform transition duration-500 hover:scale-125 inset-0"
                                    src={data.imgSrc}
                                    alt=""
                                />
                            </div>
                            <div className="text-sm p-3 space-y-2 py-5 h-1/2 bg-white rounded-xl">
                                <div className="flex space-x-2 text-xs">
                                    <p className="text-[#676767] font-light">{data.date}</p>
                                    <span className="font-light">|</span>
                                    <p className="text-[#676767] font-light">{data.timeRead}</p>
                                </div>
                                <p className="font-medium text-[#676767] w-5/6">{data.title}</p>
                                <div className="">
                                    <button className="text-base font-semibold text-[#003478]" onClick={() => handleReadMore(data)}>
                                        Read more
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* {visibleArticles < articles.length && (
                <div className="flex justify-center py-10">
                    <button onClick={handleReadMore} className="bg-[#20497d] text-white text-base px-5 py-2 rounded-md hover:bg-[#4a7cae]">
                        Load More
                    </button>
                </div>
            )} */}
        </div>
    );
}
