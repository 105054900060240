import React, { useState } from "react";
import toast from "react-hot-toast";
import Button from "../../../components/Button";
import { HiOutlineMail } from "react-icons/hi";
import Loading from "../../../components/Loading";
import NotifficationModal from "../../../components/NotifficationModal";

export default function ModalAddContactUs({ modal, onSubmit, setModal }) {
    const [isLoading, setIsLoading] = useState(false);
    const [modalData, setModalData] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    const [contactUs, setContactUs] = useState({
        name: "",
        phone_number: "",
        email: "",
        company: "",
        message: "",
    });

    const [validateInputContactUs, setValidateInputContactUs] = useState({
        name: true,
        phone_number: true,
        email: true,
        company: true,
        message: true,
    });

    const resetForm = () => {
        setContactUs({
            name: "",
            phone_number: "",
            email: "",
            company: "",
            message: "",
        });
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format validation
        if (!emailRegex.test(email)) {
            setModalData({
                show: true,
                type: "error",
                message: "Format email salah, gunakan '@' dan domain yang valid.",
            });
            return false;
        }
        return true;
    };

    const onClickAddContactUs = async (data) => {
        const dataForSubmit = {
            ...data,
        };

        const newValidationState = {
            name: !!dataForSubmit.name,
            "Phone Number": !!dataForSubmit.phone_number,
            email: !!validateEmail(dataForSubmit.email),
            company: !!dataForSubmit.company,
            message: !!dataForSubmit.message,
        };

        setValidateInputContactUs(newValidationState);

        const allValid = Object.values(newValidationState).every((value) => value);

        if (allValid) {
            setIsLoading(true);
            try {
                await onSubmit(dataForSubmit);
                resetForm();
            } catch (error) {
                setModalData({
                    show: true,
                    type: "error",
                    message: "Submission failed. Please try again.",
                });
            } finally {
                setIsLoading(false);
            }
        } else {
            const invalidKey = Object.keys(newValidationState).find((key) => !newValidationState[key]);

            setModalData({
                show: true,
                type: "error",
                message: `Data ${invalidKey} is empty or invalid`,
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContactUs({ ...contactUs, [name]: value });
    };

    const handleCloseModal = () => {
        setIsClosing(true);
        setTimeout(() => {
            setModal(false);
            setIsClosing(false);
        }, 300);
    };

    return (
        <div>
            {isLoading ? (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-20 font-light ">
                    <div className="bg-slate-600 rounded-lg p-4 w-[180px] relative px-10  max-h-[600px]  ">
                        <div className=" text-white animate-pulse">Please wait ...</div>
                    </div>
                </div>
            ) : (
                <div>
                    {modal && (
                        <div
                            className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-20 font-light transition-opacity duration-300 ${
                                isClosing ? "opacity-0" : "opacity-100"
                            }`}
                            onClick={handleCloseModal}
                        >
                            <div
                                className={`bg-white rounded-lg p-4 w-[480px] relative px-10 md:max-h-[600px] max-md:h-screen transform transition-transform duration-300 max-md:overflow-y-scroll ${
                                    isClosing ? "scale-75" : "scale-100"
                                }`}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <div className="py-4 max-md:py-28 ">
                                    <h2 className="text-lg font-semibold mb-2 text-[#616161]">How we can help you ? </h2>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-medium mb-2">Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={contactUs.name}
                                            //   className="shadow appearance-none border rounded w-full py-2 px-3 placeholder:text-gray-400 placeholder:font-normal text-gray-500 font-normal leading-tight focus:outline-none focus:shadow-outline"
                                            placeholder="Enter your name"
                                            className={`border ${
                                                validateInputContactUs.name === false ? "border-red-500" : "border-[#D5D5D5] hover:border-[#616161]"
                                            } rounded-md p-2 w-full text-sm `}
                                            required
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-medium mb-2">Phone Number</label>
                                        <input
                                            type="tel"
                                            id="phone_number"
                                            name="phone_number"
                                            value={contactUs.phone_number}
                                            onChange={handleChange}
                                            className={`border ${
                                                validateInputContactUs.phone_number === false
                                                    ? "border-red-500"
                                                    : "border-[#D5D5D5] hover:border-[#616161]"
                                            } rounded-md p-2 w-full text-sm`}
                                            //   className="shadow appearance-none border rounded w-full py-2 px-3 placeholder:text-gray-400 placeholder:font-normal text-gray-500 font-normal leading-tight focus:outline-none focus:shadow-outline"
                                            placeholder="Enter your phone number"
                                            required
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-medium mb-2">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={contactUs.email}
                                            onChange={handleChange}
                                            className={`border ${
                                                validateInputContactUs.email === false ? "border-red-500" : "border-[#D5D5D5] hover:border-[#616161]"
                                            } rounded-md p-2 w-full text-sm`}
                                            //   className="shadow appearance-none border rounded w-full py-2 px-3 placeholder:text-gray-400 placeholder:font-normal text-gray-500 font-normal leading-tight focus:outline-none focus:shadow-outline"
                                            placeholder="Enter your email"
                                            required
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-medium mb-2">Company</label>
                                        <input
                                            type="text"
                                            id="company"
                                            name="company"
                                            value={contactUs.company}
                                            onChange={handleChange}
                                            className={`border ${
                                                validateInputContactUs.company === false
                                                    ? "border-red-500"
                                                    : "border-[#D5D5D5] hover:border-[#616161]"
                                            } rounded-md p-2 w-full text-sm`}
                                            //   className="shadow appearance-none border rounded w-full py-2 px-3 placeholder:text-gray-400 placeholder:font-normal text-gray-500 font-normal leading-tight focus:outline-none focus:shadow-outline"
                                            placeholder="Enter your company"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-medium mb-2">Message</label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            value={contactUs.message}
                                            onChange={handleChange}
                                            className={`border ${
                                                validateInputContactUs.message === false
                                                    ? "border-red-500"
                                                    : "border-[#D5D5D5] hover:border-[#616161]"
                                            } rounded-md p-2 w-full text-sm`}
                                            //   className="shadow appearance-none border rounded w-full py-2 px-3 placeholder:text-gray-400 placeholder:font-normal text-gray-500 font-normal leading-tight focus:outline-none focus:shadow-outline"
                                            placeholder="Enter your message"
                                            rows="4"
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="flex justify-end space-x-5">
                                        <button className="absolute top-5 right-5  hover font-semibold px-3 py-1 " onClick={handleCloseModal}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M15.281 14.2193C15.3507 14.289 15.406 14.3717 15.4437 14.4628C15.4814 14.5538 15.5008 14.6514 15.5008 14.7499C15.5008 14.8485 15.4814 14.9461 15.4437 15.0371C15.406 15.1281 15.3507 15.2109 15.281 15.2806C15.2114 15.3502 15.1286 15.4055 15.0376 15.4432C14.9465 15.4809 14.849 15.5003 14.7504 15.5003C14.6519 15.5003 14.5543 15.4809 14.4632 15.4432C14.3722 15.4055 14.2895 15.3502 14.2198 15.2806L8.00042 9.06024L1.78104 15.2806C1.64031 15.4213 1.44944 15.5003 1.25042 15.5003C1.05139 15.5003 0.860523 15.4213 0.719792 15.2806C0.579062 15.1398 0.5 14.949 0.5 14.7499C0.5 14.5509 0.579062 14.36 0.719792 14.2193L6.9401 7.99993L0.719792 1.78055C0.579062 1.63982 0.5 1.44895 0.5 1.24993C0.5 1.05091 0.579062 0.860034 0.719792 0.719304C0.860523 0.578573 1.05139 0.499512 1.25042 0.499512C1.44944 0.499512 1.64031 0.578573 1.78104 0.719304L8.00042 6.93962L14.2198 0.719304C14.3605 0.578573 14.5514 0.499512 14.7504 0.499512C14.9494 0.499512 15.1403 0.578573 15.281 0.719304C15.4218 0.860034 15.5008 1.05091 15.5008 1.24993C15.5008 1.44895 15.4218 1.63982 15.281 1.78055L9.06073 7.99993L15.281 14.2193Z"
                                                    fill="#616161"
                                                />
                                            </svg>
                                        </button>

                                        <div onClick={() => onClickAddContactUs(contactUs)}>
                                            <Button title="Submit" status="primary" className="border-2 border-[#003478] flex space-x-3 text-xs" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {modalData.show && (
                        <NotifficationModal
                            type={modalData.type}
                            title={modalData.title}
                            message={modalData.message}
                            onClose={() => setModalData({ ...modalData, show: false })}
                        />
                    )}
                </div>
            )}
        </div>
    );
}
