import React from "react";

const typographyStyles = {
    h1: "xl:text-4xl lg:text-3xl md:text-2xl text-3xl",
    h2: "xl:text-3xl lg:text-2xl md:text-xl text-2xl",
    h3: "xl:text-2xl lg:text-xl md:text-lg text-xl",
    subtitle: "text-xl font-medium text-gray-700",
    paragraph: "xl:text-xl lg:text-lg md:text-base text-sm font-normal text-[#616161] h-fit",
    //  paragraph: "xl:text-xl lg:text-lg md:text-base text-sm font-light text-[#616161]",
    caption: "text-sm font-light text-gray-600",
    small: "xl:text-base lg:text-sm md:text-sm text-base ",
};

export default function Typography({ variant = "", color = "", align = "text-left", className = "", children }) {
    const classes = `${typographyStyles[variant]} ${color} ${align} ${className}`;
    const Tag = variant === "paragraph" ? "p" : variant.startsWith("h") ? variant : "span";
    return <Tag className={classes}>{children}</Tag>;
}
