import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";

// Background
import aboutusBg from "../../assets/aboutusbg.png";
import careerHeader from "../../assets/careerHeader.png";
import careerContent from "../../assets/careerContent.png";
import chooseCareerCRI from "../../assets/chooseCareerCRI.png";

// clisnts
import conocoPhilips from "../../assets/clients/conocoPhilips.png";
import spie from "../../assets/clients/spie.svg";
import pertamina from "../../assets/clients/pertamina.svg";
import Button from "../../components/Button";
import Section from "../../components/Section";
// icon
import comitmentExcellence from "../../assets/Icon/Career/ourvalues/commitementExcelent.png";
import innovationandContinous from "../../assets/Icon/Career/ourvalues/InnovationadnContinousImprovements.png";
import comprehensiveSolutions from "../../assets/Icon/Career/ourvalues/comperhensiveSolutions.png";
import startegicPartnership from "../../assets/Icon/Career/ourvalues/comperhensiveSolutions.png";

// icon Join
import profesionalGrowth from "../../assets/Icon/Career/benefit/profesionalGrowth.png";
import diverseChalange from "../../assets/Icon/Career/benefit/diverseandChalangging.png";
import collaborativeSUpportive from "../../assets/Icon/Career/benefit/collaborativeandSupportive.png";
import cuttingEdge from "../../assets/Icon/Career/benefit/CuttingEdgeTech.png";
import impactRecognation from "../../assets/Icon/Career/benefit/impactandRecognation.png";
import startegicPartnershipReach from "../../assets/Icon/Career/benefit/StrategicPartnershipReach.png";
import { FaArrowRight } from "react-icons/fa";

// benefit
import mentorshipFromReliability from "../../assets/Icon/Career/whyJoin/mentorShip.png";
import leaderhip from "../../assets/Icon/Career/whyJoin/leadership.png";
import stateOfArt from "../../assets/Icon/Career/whyJoin/stateTneArt.png";
import diverseClient from "../../assets/Icon/Career/whyJoin/diversce.png";
import compliment from "../../assets/Icon/Career/whyJoin/compliment.png";
import sports from "../../assets/Icon/Career/whyJoin/sports.png";
import { Navigate, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";

export default function CareerContainer() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeData, setActiveData] = useState(null);
    const navigate = useNavigate();

    const ourValues = [
        {
            imgSrc: comitmentExcellence,
            title: "Software: Asset Integrity & Reliability Management System Phase 1: Asset Register, Spare Part Management, and Bill of Material",
            description:
                "Our portfolio showcases our expertise in delivering high-quality, customized solutions. By specializing in FMEA, RCFA, and RCM, we have improved the efficiency and safety of many organizations. Our commitment to excellence ensures top-quality and reliable results in every project.",
        },
        {
            imgSrc: innovationandContinous,
            title: "Software: Asset Integrity & Reliability Management System Phase 1: Asset Register, Spare Part Management, and Bill of Material",
            description:
                "At CRI, we don’t just finish projects; we set new industry standards. Our work in enhancing Maintenance & Reliability Data visibility and implementing Equipment/Machinery Health programs has transformed industry practices. We promote continuous improvement and knowledge sharing, ensuring our clients benefit from the latest Reliability advancements",
        },
        {
            imgSrc: comprehensiveSolutions,
            title: "Software: Asset Integrity & Reliability Management System Phase 1: Asset Register, Spare Part Management, and Bill of Material",
            description: "SPIE Oil and Gas Thailand (North Oil Company - Qatar) (2023)",
        },
        {
            imgSrc: startegicPartnership,
            title: "Software: Asset Integrity & Reliability Management System Phase 1: Asset Register, Spare Part Management, and Bill of Material",
            description: "SPIE Oil and Gas Thailand (North Oil Company - Qatar) (2023)",
        },
    ];
    const whyJoin = [
        {
            imgSrc: profesionalGrowth,
            title: "Professional Growth and Development",
            description:
                "At CRI, we are committed to the continuous professional growth of our employees. We offer a range of training programs, workshops, and mentorship opportunities designed to enhance your skills and knowledge. Whether you are an experienced professional or a fresh graduate, you will find ample opportunities to learn and advance your career. Our focus on innovation ensures that you are always at the forefront of the latest industry trends and technologies.",
        },
        {
            imgSrc: diverseChalange,
            title: "Diverse and Challenging Projects",
            description:
                "Working at CRI means being part of a team that tackles diverse and challenging projects across various industries. Our extensive portfolio includes over 100 successful projects in Indonesia, Malaysia, Thailand, and Qatar. This diversity not only broadens your experience but also allows you to apply your skills in different contexts, making your work both exciting and rewarding.",
        },
        {
            imgSrc: collaborativeSUpportive,
            title: "Collaborative and Supportive Environment",
            description:
                "We believe that the best ideas come from collaboration. At CRI, you will work in a supportive environment where teamwork and knowledge sharing are encouraged. Our culture fosters open communication and mutual respect, ensuring that every team member feels valued and heard. This collaborative spirit helps us achieve excellence in everything we do.",
        },
        {
            imgSrc: cuttingEdge,
            title: "Cutting-Edge Technology and Tools",
            description:
                "CRI is at the forefront of technological innovation in the Reliability industry. Our proprietary web-based platform, IMEX, is a testament to our commitment to providing the best tools for our clients and employees. By working with cutting-edge technology, you will have the opportunity to develop your technical skills and contribute to groundbreaking solutions that set new industry standards..",
        },
        {
            imgSrc: impactRecognation,
            title: "Impact and Recognition",
            description:
                "At CRI, your work has a direct impact on the success of our clients and the advancement of industry standards. We recognize and celebrate the contributions of our team members, ensuring that your efforts are acknowledged and rewarded. By joining CRI, you become part of a company that values excellence and strives to make a positive difference in the Reliability industry.",
        },
        {
            imgSrc: startegicPartnershipReach,
            title: "Strategic Partnerships and Global Reach",
            description:
                "Our strategic partnerships and international projects provide you with the opportunity to work with leading organizations and experts worldwide. This global reach not only enhances your professional network but also exposes you to different cultures and practices, enriching your career experience.",
        },
    ];
    const benefits = [
        {
            imgSrc: mentorshipFromReliability,
            title: "Mentorship from Reliability Experts",
            description:
                "At CRI, we value mentorship. Our team has top experts in the Reliability industry. As a member, you’ll learn from them, gaining insights and guidance to boost your career and skills.",
        },
        {
            imgSrc: leaderhip,
            title: "Leadership Development",
            description:
                "We are dedicated to developing future leaders. At CRI, you can grow your leadership skills as a Project Manager. This role lets you lead teams, manage projects, and innovate, preparing you for higher leadership positions in your career.",
        },
        {
            imgSrc: stateOfArt,
            title: "State-of-the-Art Working Tools",
            description:
                "CRI gives you all the tools you need, like a laptop and accessories, so you can focus on doing great work and reaching your goals",
        },
        {
            imgSrc: diverseClient,
            title: "Diverse Client Industries",
            description: "CRI is at the forefront of technological innovation in the Reliability industry.",
        },
        {
            imgSrc: compliment,
            title: "Complimentary Meals and Snacks",
            description:
                "CRI offers free lunch, snacks, and a coffee & tea station to keep you energized. Enjoy tasty meals and refreshments while working with your colleagues",
        },
        {
            imgSrc: sports,
            title: "Sports and Wellness",
            description:
                "At CRI, we care about your well-being. We offer a free gym station and weekly sports activities such as badminton and swimming. These amenities help you stay fit, relieve stress, and build camaraderie with your teammates, ensuring a healthy work-life balance.",
        },
    ];

    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "Career", path: "/career" },
    ];

    const handleVacanciesPage = () => {
        navigate(`/career/OurVacancies`);
    };

    const openModal = (data) => {
        setActiveData(data);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isModalOpen]);

    return (
        <div className="h-full">
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center py-10 ">
                    {/* <div className="max-md:bg-white max-md:h-screen max-md:flex justify-center items-center"> */}
                    <div
                        className="relative bg-white p-8 max-md:py-14 md:min-h-[300px] max-md:h-screen rounded-xl max-w-lg w-full  transition-transform duration-300 transform ease-in-out scale-100 "
                        // onClick={(e) => e.stopPropagation()}
                    >
                        <div className="py-5">
                            <h1 className="text-2xl font-semibold mb-4">{activeData?.title}</h1>
                            <p className="text-sm font-normal">{activeData?.description}</p>
                            <div className=" pt-4 absolute bottom-5 right-10">
                                <Button status="primary" onClick={closeModal} title={"Close"} />
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            )}
            <Header bgColor="bg-white" className="h-screen md:h-[30rem] relative overflow-hidden max-md:hidden" position="end">
                <div className="h-full w-full max-w-[1920px] mx-auto">
                    <div className=" h-full z-30 text-4xl">
                        <div className="text-[#003478] text-lg font-normal">Work at CRI</div>
                        <div className="text-[#003478] max-w-xl">
                            Let’s grow your <span className="text-[#67B7FD]">potential</span> career with CRI.
                        </div>
                    </div>
                    <div className="absolute -z-10 top-0 right-0 w-full md:h-[35rem] h-full md:bg-gradient-to-r bg-gradient-to-b  md:from-white md:via-white/90  md:to-white/30 from-white/70 via-white/85 to-white/20  "></div>
                    <img className="absolute right-0 top-0 object-cover md:h-[35rem] md:w-[95rem] h-full -z-50 " src={careerHeader} alt="" />
                </div>
            </Header>
            <Header bgColor="bg-white" className="h-screen md:h-[30rem] relative overflow-hidden md:hidden" position="center">
                <div className="h-full w-full">
                    <div className=" h-full z-30 text-4xl">
                        <div className="text-[#003478] text-lg font-normal">Work at CRI</div>
                        <div className="text-[#003478] max-w-xl">
                            Let’s grow your <span className="text-[#67B7FD]">potential</span> career with CRI.
                        </div>
                    </div>
                    <div className="absolute -z-10 top-0 right-0 w-full md:h-[35rem] h-full md:bg-gradient-to-r bg-gradient-to-b  md:from-white md:via-white/90  md:to-white/30 from-white/70 via-white/85 to-white/20  "></div>
                    <img className="absolute left-0 top-0 object-cover md:h-[35rem] md:w-[95rem] h-full -z-50 " src={careerHeader} alt="" />
                </div>
            </Header>
            <Breadcrumbs breadcrumbs={breadcrumb} className={"bg-[#F6FBFF]"} />
            <main className="text-lg text-[#616161] ">
                <section className="flex justify-center items-center bg-[#F6FBFF]  text-white xl:px-32 md:px-5 py-10 space-x-20 h-[24.5rem]">
                    <div className="space-y-10 px-5 lg:flex justify-center items-center w-full max-w-[1920px]">
                        <h1 className="text-[#003478] lg:text-5xl text-4xl lg:font-semibold lg:w-1/3">
                            Our <span className="text-[#67B7FD]">Values</span>
                        </h1>
                        <div className="text-base text-[#616161] font-light flex-1">
                            At CRI, we believe that excellence in the Reliability industry is not just a goal, but a continuous journey. Since our
                            inception in January 2014, we have been pioneers in advancing Reliability standards in Indonesia and beyond. Our
                            commitment to innovation and quality has enabled us to successfully complete over 100 projects, serving a diverse range of
                            clients across Indonesia, Malaysia, Thailand, and Qatar.
                        </div>
                    </div>
                </section>
                <div className=" w-full bg-[#F6FBFF] ">
                    <Section className="w-full xl:px-32 lg:px-24 md:px-16 px-5 md:py-14 scroll m-0 max-md:hidden">
                        <div className="flex justify-start items-center w-full">
                            <div className="flex py-10 space-x-10 ">
                                {ourValues.map((data, index) => (
                                    <div className=" lg:w-[400px] w-[300px] h-[300px]" key={index}>
                                        <div className="space-y-3">
                                            <img src={data.imgSrc} alt="" className="w-11" />
                                            <h1 className="font-semibold text-base">{data.title}</h1>
                                            <p className="font-light text-sm">{data.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Section>
                    <Section className="w-full xl:px-32 lg:px-24 md:px-16 px-5 md:py-14 scroll m-0 md:hidden">
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center py-10 space-y-10 w-full ">
                                {ourValues.map((data, index) => (
                                    <div className="" key={index}>
                                        <div className="space-y-3">
                                            <img src={data.imgSrc} alt="" className="w-11" />
                                            <h1 className="font-semibold text-base">{data.title}</h1>
                                            <p className="font-light text-sm">{data.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Section>
                </div>
                <Section className="h-full bg-[#003478] flex justify-center items-center lg:px-20 px-5 ">
                    <div className="w-full space-y-10 py-10">
                        <div className="text-center text-white space-y-10 ">
                            <h1 className="lg:text-5xl text-3xl">
                                Why Join <span className="text-[#67B7FD]"> CRI? </span>
                            </h1>
                            <p className="font-light ">
                                Building a career at CRI offers numerous benefits that make it an attractive choice for professionals at all stages of
                                their careers. Here are some compelling reasons to join our team:
                            </p>
                        </div>
                        <div className="w-full h-full px-4 overflow-x-auto text-white">
                            <div className="grid md:grid-cols-2 xl:grid-cols-3 xl:gap-6 gap-6 justify-center items-center w-full h-full ">
                                {whyJoin.map((data, index) => (
                                    <div
                                        key={index}
                                        className="relative w-full xl:h-[400px] h-[338px] space-y-3 bg-white/15 rounded-2xl p-10 group transition duration-300 ease-in-out hover:bg-white hover:text-black hover:cursor-pointer"
                                        onClick={() => openModal(data)}
                                    >
                                        <img src={data.imgSrc} alt="" className="lg:w-20 object-cover" />
                                        <h1 className="font-semibold lg:text-2xl text-xl transition duration-100 ease-in-out">{data.title}</h1>
                                        <p className="font-normal lg:text-sm text-sm text-[#C2C2C2] transition duration-300 ease-in-out group-hover:text-black">
                                            {data.description.length > 80 ? data.description.substring(0, 80) + "..." : data.description}
                                        </p>
                                        <div className="absolute bottom-10 text-base font-normal text-white transition duration-300 ease-in-out group-hover:text-black">
                                            <div className="flex justify-center items-center space-x-3 hover:cursor-pointer">
                                                <button className="">Read more</button>
                                                <FaArrowRight className="" />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Section>
                <Section className="h-full bg-[#F6FBFF] xl:px-20 xl:py-20 px-5 ">
                    <div className="w-full lg:flex justify-center items-start">
                        <div className="text-start text-[#003478] space-y-5 lg:w-1/3 w-full ">
                            <h1 className="lg:text-5xl max-lg:text-4xl font-semibold">Benefits</h1>
                            <p className="font-light text-base text-[#616161]">
                                Building a career at CRI offers numerous benefits that make it an attractive choice for professionals at all stages of
                                their careers. Here are some compelling reasons to join our team:
                            </p>
                            <img src={careerContent} alt="" />
                        </div>
                        <div className="flex-1 w-full h-full lg:px-4 overflow-x-auto ">
                            <div className="grid grid-cols-1 gap-6 justify-center items-center w-full h-full md:py-0 py-10 ">
                                {benefits.map((member, index) => (
                                    <div className="relative w-full space-y-3 bg-white/15 rounded-2xl h-full py-2" key={index}>
                                        <div className="md:flex grid items-center space-x-5 bg-white w-full md:px-4 md:py-6 lg:drop-shadow-lg h-full">
                                            <div className="max-md:px-5 md:w-20">
                                                <img
                                                    src={member.imgSrc}
                                                    alt=""
                                                    className="flex xl:w-20 xl:h-20 md:w-16 md:h-16 max-md:w-20  max-md:h-w-20  object-cover    "
                                                />
                                            </div>
                                            <div className="md:flex-1 xl:space-y-2 text-[#616161]">
                                                <h1 className="font-medium xl:text-2xl lg:text-xl text-lg">{member.title}</h1>
                                                <p className="font-light lg:text-sm ">{member.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Section>
                <Header className="xl:h-[492px] h-full md:py-0 py-10" bgColor="bg-blue-50">
                    <div className="w-full h-full ">
                        <img className="absolute -z-50 top-0 left-0 object-cover h-full w-full " src={chooseCareerCRI} alt="" />
                        <div className="absolute -z-10 top-0 right-0 w-full h-full  bg-[#093165CC]/80 "></div>
                    </div>
                    <h1 className="text-center pb-10">Choose your Career in CRI</h1>

                    <div className="md:flex justify-center items-center md:space-x-10">
                        <div className="text-white xl:w-[385px] xl:h-[236px]  flex flex-col gap-y-4 justify-center items-center text-center  md:backdrop-blur-3xl md:bg-white/5 rounded-xl md:border border-blue-100 p-3">
                            <h1 className="h-1/3 flex items-center  md:text-[28px] text-2xl">Experience</h1>
                            <p className=" flex-1 md:text-base text-sm font-normal text-center tracking-normal mb-4">
                                We value the depth of knowledge and expertise that experienced professionals bring to our team. We understand that
                                your skills and insights are crucial to driving innovation and excellence in the Reliability industry.
                            </p>
                        </div>
                        <div className="text-white xl:w-[385px] xl:h-[236px] flex flex-col gap-y-4 justify-center items-center text-center  md:backdrop-blur-3xl md:bg-white/5 rounded-xl md:border border-blue-100 p-3">
                            <h1 className="h-1/3 flex items-center  md:text-[28px] text-2xl">Fresh Graduate</h1>
                            <p className="flex-1 md:text-base text-sm font-normal text-center tracking-normal mb-4">
                                Are you a fresh graduate eager to make your mark in the engineering world? At CRI, we believe in the power of young
                                talent and fresh perspectives.
                            </p>
                        </div>
                        <div className="text-white xl:w-[385px] xl:h-[236px]  flex flex-col gap-y-4 justify-center items-center text-center  md:backdrop-blur-3xl md:bg-white/5 rounded-xl md:border border-blue-100 p-3">
                            <h1 className="h-1/3 flex items-center  md:text-[28px] text-2xl">Internship</h1>
                            <p className="flex-1 md:text-base text-sm font-normal  text-center tracking-normal mb-4">
                                CRI is committed to nurturing the next generation of engineering professionals. Our internship program is designed to
                                provide young individuals with the opportunity to develop their skills and gain valuable industry experience.
                            </p>
                        </div>
                    </div>
                </Header>
                <section className="md:flex grid justify-center items-center md:px-32 px-5 py-24 md:space-x-20 space-y-5 ">
                    <div className="md:w-1/5">
                        <div className="text-4xl font-semibold ">Explore our vacancies</div>
                    </div>
                    <div className="flex-1 md:space-y-5 space-y-10 text-base font-light ">
                        <p>
                            With a team composition that is experienced in its field, we strive to maintain client trust to continue to provide the
                            best service for our clients.
                        </p>
                        <Button onClick={handleVacanciesPage} title="See our vacancies" status="primary" />
                    </div>
                </section>
            </main>
        </div>
    );
}
