import React from "react";
import clisteLogo from "../assets/criBiru.svg";

export default function Loading() {
    return (
        <div className="loading-container bgblue bg-background">
            <div className="logo-container">
                <img src={clisteLogo} alt="Loading Logo" className=" loading-logo " />
            </div>
        </div>
    );
}
