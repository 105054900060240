import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Section from "../../../components/Section"; // Assuming Section component is already built
import Button from "../../../components/Button"; // Assuming Button component is already built
import Card from "../../../components/Card"; // Assuming Card component is already built

export default function TeamSection({ teamMembers }) {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const carouselRef = useRef(null);

    // Check screen size on load and resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1024); // Laptop screens typically >= 1024px
        };

        handleResize(); // Check screen size on component mount
        window.addEventListener("resize", handleResize); // Listen for screen size changes

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (carouselRef.current && isMobile) {
            carouselRef.current.scrollLeft = scrollPosition;
        }
    }, [scrollPosition, isMobile]);

    const top3TeamMembers = teamMembers?.slice(0, 3);

    return (
        <>
            <div className="md:flex lg:space-x-10 md:pt-0 h-full md:space-y-0 max-md:space-y-4">
                <div
                    className="w-full lg:w-1/4 md:w-1/3 xl:text-4xl lg:text-3xl md:text-2xl text-3xl font-bold text-center md:text-left leading-tight"
                    data-aos="fade-right"
                >
                    Our Passionate <span className="text-[#48A4F4]">Team</span>
                </div>
                <div
                    className="w-full flex-1 items-start text-sm xl:text-xl text-center md:text-left md:mt-0 xl:px-10 md:px-0 flex justify-start"
                    data-aos="fade-left"
                >
                    <p className="font-light w-full xl:text-xl lg:text-lg md:text-base text-sm" data-aos="fade-right">
                        With a team composition that is experienced in its field, we strive to maintain client trust to continue to provide the best
                        service for our clients.
                    </p>
                </div>
            </div>

            <div className="md:mt-10 py-4 h-full space-y-10">
                <div className="md:h-full md:space-x-14 flex justify-center items-center max-md:flex-col overflow-hidden md:overflow-visible w-full px-4 md:px-0 max-md:max-w-[768px]">
                    {top3TeamMembers.map((data, index) => (
                        <div key={index} className="md: w-full" data-aos="zoom-in">
                            <Card
                                className="bg-white xl:w-[300px] lg:w-[280px] md:w-[200px] max-md:[300px] w-[300px] h-full "
                                imageSrc={data.imageSrc}
                                name={data.name}
                                position={data.position}
                            />
                        </div>
                    ))}
                </div>
                <div className="w-full flex justify-center items-center py-10">
                    <Link to={"/aboutus"}>
                        <Button title="Meet Our Team" status="primary" />
                    </Link>
                </div>
            </div>
        </>
    );
}
