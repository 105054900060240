import React from "react";

export default function Section({ children, className = "", containerClassName = "" }) {
    return (
        <section className={`flex justify-center items-center w-full ${className}`}>
            {/* <div className={`md:px-4 sm:px-6 lg:px-8 ${containerClassName}`}>{children}</div> */}
            {children}
        </section>
    );
}
