import React, { useState, useRef, useEffect } from "react";
import AOS from "aos";
import Header from "../../components/Header";
import clisteLogo from "../../assets/3dLogoCRIRev.svg";
import layerCard from "../../assets/layerCard.png";
import { useNavigate } from "react-router-dom";

import { FaArrowRightLong } from "react-icons/fa6";

import { industries, services } from "../../Data/data";
import Breadcrumbs from "../../components/Breadcrumbs";
import useFetchApiGet from "../../Hooks/useFetchApiGet";
import FormatDateToText from "../../Constant/FormatDateToText";
import Typography from "../../components/Typography";
import Button from "../../components/Button";

export default function ServicesCointainer() {
    console.log("services", services);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const navigate = useNavigate();

    // const { data: services, loading: loadingServices, fetchApiGet: fetchApiGetServices, setData: setServices } = useFetchApiGet();
    // console.log("services", services);
    // const onGetServices = async (id) => {
    //     try {
    //         const response = await fetchApiGetServices(`/service/list/1/10/all`);
    //         if (response.status === 200) {
    //             console.log("Services", response);

    //             const formatData = response.data.data?.map((d) => ({
    //                 ...d,
    //                 dateToText: FormatDateToText(d.created_at),
    //                 updatedDateToText: FormatDateToText(d.updated_at),
    //                 imageUrl: `data:image/png;base64,${d.image}`,
    //             }));
    //             console.log("services", formatData);

    //             // Set the formatted data in state
    //             setServices(formatData);
    //         }
    //     } catch (error) {
    //         console.log("Error fetching services:", error);
    //     }
    // };
    const { data: industry, loading: loadingIndustry, fetchApiGet: fetchApiGetIndustry, setData: setIndustry } = useFetchApiGet();
    console.log("services", services);
    const onGetIndustry = async (id) => {
        try {
            const response = await fetchApiGetIndustry(`/industry/list/1/10/all`);
            if (response.status === 200) {
                const formatData = response.data.data?.map((d) => ({
                    ...d,
                    dateToText: FormatDateToText(d.created_at),
                    updatedDateToText: FormatDateToText(d.updated_at),
                    imageUrl: `data:image/png;base64,${d.image}`,
                }));
                console.log("industries", formatData);

                // Set the formatted data in state
                setIndustry(formatData);
            }
        } catch (error) {
            console.log("Error fetching services:", error);
        }
    };

    const handleDetailCardClick = (service) => {
        navigate(`/services/${service.id}`);
    };

    const handleCardClick = (service) => {
        setSelectedService(service);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedService(null);
    };

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isModalOpen]);
    useEffect(() => {
        AOS.init({
            duration: 800,
            once: true,
            offset: 100,
            easing: "ease-in-out",
        });
    }, []);
    const itemsPerPage = 2;
    const totalItems = industries.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    console.log("total pages", totalPages);
    console.log("total item", totalItems);

    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "Services", path: "/services" },
    ];
    const dataText = `CRI specialize in providing comprehensive, reliability-based asset life cycle management. Our expertise spans every phase of the asset life cycle, from conception and design to construction, commissioning, operation, and decommissioning.
                    /nAs a world-class consultancy support company, CRI offers a wide range of services designed to meet the highest standards of Safety, Reliability, Availability, Maintainability, and Asset Integrity. Our offerings include: Consultancy Services (Expert guidance to optimize your operations and ensure reliability), Training (Comprehensive training programs to enhance your team’s skills and knowledge), Software Renting and Development (Customizable software solutions tailored to your specific needs), Reverse Engineering (Innovative solutions to replicate and improve existing systems), Product Development (Cutting-edge product development to meet industry demands), and Manpower Support (Skilled professionals to support your operational needs).
                    /nOur commitment to Maintenance & Reliability Excellence (MREX) ensures that we deliver unparalleled service and support, helping you achieve operational excellence and efficiency. At CRI, our primary focus is on delivering the best possible outcomes for our clients. Trust us to bring the highest level of expertise and dedication to your projects.`;
    const paragraphText = dataText.split("/n");

    // useEffect(() => {
    //     onGetServices();
    //     onGetIndustry();
    // }, []);
    return (
        <div className="h-full w-full overflow-hidden">
            {isModalOpen && selectedService && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center py-15 ">
                    <div className="bg-white rounded-lg xl:p-10 md:w-[522px] xl:h-[602px] lg:h-[562px] md:h-[530px]   w-full h-screen px-5 relative  overflow-hidden">
                        <div className="py-5">
                            <h2 className="lg:text-3xl text-center font-bold mb-4" data-aos="fade-zoom-up">
                                {selectedService.title}
                            </h2>

                            {selectedService.content && selectedService.content.length > 0 && (
                                <div className="overflow-hidden ">
                                    {selectedService.content.map((contentItem, index) => (
                                        <div key={index} className="mb-6 text-black ">
                                            <h3
                                                className="text-base font-semibold mb-2"
                                                data-aos="fade-left"
                                                data-aos-anchor="#example-anchor"
                                                data-aos-offset="500"
                                                data-aos-duration="500"
                                            >
                                                {contentItem.title}
                                            </h3>

                                            {contentItem.subTitle && contentItem.subTitle.length > 0 && (
                                                <div className="list-disc">
                                                    {contentItem.subTitle.map((subItem, subIndex) => (
                                                        <div key={subIndex} className="mb-2">
                                                            <p
                                                                className="font-normal text-sm text-gray-600"
                                                                data-aos="fade-left"
                                                                data-aos-anchor="#example-anchor"
                                                                data-aos-offset="500"
                                                                data-aos-duration="500"
                                                            >
                                                                {subItem.content}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            {contentItem.subContent && contentItem.subContent.length > 0 && (
                                                <ul className="pl-4 list-disc  min-h-[200px]">
                                                {/* <ul className="pl-4 list-disc overflow-y-scroll max-h-[250px]"> */}
                                                    {contentItem.subContent.map((subItem, subIndex) => (
                                                        <li key={subIndex} className="ml-4 font-normal">
                                                            <p
                                                                className=" text-sm text-gray-600"
                                                                data-aos="fade-left"
                                                                data-aos-anchor="#example-anchor"
                                                                data-aos-offset="500"
                                                                data-aos-duration="500"
                                                            >
                                                                {subItem.content}
                                                            </p>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="pt-4 absolute bottom-5 right-5">
                            <Button title="Close" onClick={closeModal} status="primary" />
                        </div>
                    </div>
                </div>
            )}

            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="absolute top-0 h-screen md:hidden"
                position="center"
            >
                <div className="md:text-4xl text-3xl space-y-1">
                    {/* <div className="absolute w-full top-0 bg-gradient-to-r from-blue-950/100 to-blue-300/5 z-10"></div> */}

                    <div>
                        <div className="text-[#fefefe] md:text-xl text-lg font-normal">Our Services</div>
                        <div>Find best solutions for your business</div>
                        <div className="text-[#67B7FD] ">with us.</div>
                    </div>
                    <img className="absolute right-16 -bottom-10 scale-125 md:visible hidden" src={clisteLogo} alt="" />
                </div>
            </Header>
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="absolute top-0 xl:h-[504px] lg:h-[400px] md:h-[300px] h-screen max-md:hidden"
                imageContent={clisteLogo}
                position="end"
            >
                <div className="md:text-4xl text-3xl space-y-1">
                    <div className="absolute w-full top-0 bg-gradient-to-r from-blue-950/100 to-blue-300/5 z-10"></div>
                    <div className="flex justify-center items-center">
                        <div className="flex w-full relative justify-start max-w-[1920px]">
                            <Typography variant="h1" color="" align="" className="leading-tight">
                                <div className="text-[#fefefe] xl:text-xl lg:text-lg md:text-base text-lg font-normal" data-aos="fade-down">
                                    Our Services
                                </div>
                                <div className="xl:text-4xl lg:text-3xl md:text-2xl text-4xl" data-aos="fade-down">
                                    Find best solutions for your business
                                </div>
                                <div className="text-[#67B7FD] xl:text-4xl lg:text-3xl md:text-2xl text-4xl" data-aos="fade-down">
                                    with us.
                                </div>
                            </Typography>
                            <img
                                className="absolute xl:-right-6 xl:-bottom-28 lg:-bottom-20 md:-bottom-14 -right-6 -bottom-28 xl:w-[450px] lg:w-[350px] md:w-[255px] md:visible drop-shadow-lg"
                                src={clisteLogo}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </Header>

            <main className="md:px-10  md:text-lg text-sm space-y-10 text-[#616161] bg-background">
                <Breadcrumbs breadcrumbs={breadcrumb} />
                <div className="xl:space-y-22 space-y-20 px-5">
                    <div className="xl:w-[1560px] max-w-screen-xl mx-auto">
                        <div className="xl:px-14 lg:px-12 lg:py-5 font-normal text-base xl:text-xl lg:text-lg md:text-base  max-md:text-sm ">
                            {paragraphText.map((paragraph, index) => (
                                <p key={index} className="mb-4" data-aos="zoom-in">
                                    {paragraph}
                                </p>
                            ))}
                        </div>
                    </div>
                    <div className=" py-4 space-y-7 text-netural_90 xl:w-[1360px] mx-auto">
                        <h1 className="font-semibold xl:text-4xl lg:text-3xl md:text-2xl text-3xl text-center text-text_90 " data-aos="fade-down">
                            Our Services
                        </h1>
                        <p className="xl:text-xl lg:text-lg md:text-base text-sm text-center font-normal text-text_70">
                            Our commitment is to deliver the highest quality in our every service and product – keeping your company’s performance
                            in-line with international standards.
                        </p>

                        <div className="flex w-full justify-center">
                            <div className="card flex justify-start space-x-5 md:space-x-10 overflow-x-scroll px-4 md:px-0 w-full hover:cursor-pointer">
                                {services.map((data) => (
                                    <div
                                        key={data.id}
                                        className="w-[18rem] md:w-[24.125rem] h-[14rem] md:h-[18.875rem] bg-slate-300 rounded-2xl overflow-hidden relative text-white flex-shrink-0"
                                        data-aos="zoom-in"
                                        data-aos-duration="1000"
                                        onClick={() => handleCardClick(data)}
                                    >
                                        <img
                                            className="absolute z-20 w-full h-full object-cover pointer-events-none rounded-2xl"
                                            src={layerCard}
                                            alt=""
                                        />
                                        <img
                                            className="w-full h-full object-cover transform transition duration-500 hover:scale-105"
                                            src={data.image}
                                            alt={data.title}
                                        />
                                        <div className="absolute z-30 bottom-0 px-5 py-3 space-y-2 md:px-7 md:py-5">
                                            <h1 className="font-semibold text-lg md:text-2xl">{data.title}</h1>
                                            <p className="text-xs md:font-light">{data.description}</p>
                                            <div className="flex justify-start items-center space-x-2">
                                                <span className="text-xs">More Details</span>
                                                <FaArrowRightLong className="text-xs" />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="py-4 space-y-7 text-[#404040] xl:w-[1360px] mx-auto ">
                        <h1 className="font-semibold text-4xl text-center text" data-aos="fade-down">
                            Industries
                        </h1>

                        <div className="w-full space-y-10">
                            <div
                                className="card flex  justify-start space-x-5 md:space-x-10 overflow-x-scroll px-4 md:px-0 w-full"
                                data-aos="zoom-in"
                                data-aos-duration="1000"
                            >
                                {industries.map((data) => (
                                    <div
                                        key={data.id}
                                        className="w-[18rem] md:w-[24.125rem] h-[14rem] md:h-[18.875rem] bg-slate-300 rounded-2xl overflow-hidden relative text-white flex-shrink-0 hover:cursor-pointer"
                                        onClick={() => handleDetailCardClick(data)}
                                    >
                                        <img
                                            className="absolute z-20 w-full h-full object-cover pointer-events-none rounded-2xl"
                                            src={layerCard}
                                            alt=""
                                        />
                                        <img
                                            className="w-full h-full object-cover transform transition duration-500 hover:scale-105"
                                            src={data.image}
                                            alt={data.title}
                                        />
                                        <div className="absolute z-30 bottom-0 px-5 py-3 space-y-2 md:px-7 md:py-5">
                                            <h1 className="font-semibold text-lg md:text-2xl">{data.title}</h1>
                                            <p className="text-xs md:font-light">{data.description}</p>
                                            <div className="flex justify-start items-center space-x-2">
                                                <span className="text-xs">More Details</span>
                                                <FaArrowRightLong className="text-xs" />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
