import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { HiOutlineMail } from "react-icons/hi";
import useFetchApiPost from "../../Hooks/useFetchApiPost";
import ModalAddContactUs from "./Modal/ModalAddContactUs";
import toast from "react-hot-toast";
import Button from "../../components/Button";
import { FaGlobe, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import instagramIcon from "../../assets/Icon/instagram.svg";
import linkedinIcon from "../../assets/Icon/Frame.svg";
import NotifficationModal from "../../components/NotifficationModal";

export default function ContactusContainer() {
    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contactUs, setContactUs] = useState({
        name: "",
        phone_number: "",
        email: "",
        company: "",
        message: "",
    });

    const [validateInputContactUs, setValidateInputContactUs] = useState({
        name: true,
        phone_number: true,
        email: true,
        company: true,
        message: true,
    });

    const resetForm = () => {
        setContactUs({
            name: "",
            phone_number: "",
            email: "",
            company: "",
            message: "",
        });
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const onClickAddContactUs = (data) => {
        const dataForSubmit = {
            ...data,
        };

        const newValidationState = {
            name: !!dataForSubmit.name,
            phone_number: !!dataForSubmit.phone_number,
            email: !!dataForSubmit.email,
            company: !!dataForSubmit.company,
            message: !!dataForSubmit.message,
        };
        setValidateInputContactUs(newValidationState);
        const allValid = Object.values(newValidationState).every((value) => value);

        if (allValid) {
            onPostContactUs(contactUs);
        } else {
            const invalidKey = Object.keys(newValidationState).find((key) => !newValidationState[key]);
            setModalData({
                show: true,
                type: "error",
                message: `Data ${invalidKey} is empty`,
            });
        }
    };

    const handleOpenMapRemoteOffice = () => {
        window.open("https://maps.app.goo.gl/YZ8E8bKaBzJv47T68", "_blank");
    };
    const handleOpenMapHeadOffice = () => {
        window.open("https://maps.app.goo.gl/YZ8E8bKaBzJv47T68", "_blank");
    };

    const { loading: loadingPost, fetchApiPost: fetchApiPostContactUs } = useFetchApiPost();

    const onPostContactUs = async (data) => {
        setLoading(true); // Mulai loading state
        try {
            const formData = new FormData();
            formData.append("Name", data.name);
            formData.append("Phone_Number", data.phone_number);
            formData.append("Email", data.email);
            formData.append("Company", data.company);
            formData.append("Message", data.message);
            const response = await fetchApiPostContactUs(`/contact-us/add`, formData);

            if (response.status === 201) {
                setModalData({
                    show: true,
                    type: "success",
                    message: response.message,
                });
            } else {
                setModalData({
                    show: true,
                    type: "error",
                    message: response.message,
                });
            }
        } catch (error) {
            const errorMessage = error.response?.message || "Something went wrong. Please try again later.";
            setModalData({
                show: true,
                type: "error",
                message: errorMessage,
            });
        }
        setLoading(false);
        setModal(false);
    };

    const handleOpenModal = () => {
        setModal(true);
    };

    useEffect(() => {
        if (modal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [modal]);

    return (
        <div className="h-full">
            <ModalAddContactUs modal={modal} setModal={setModal} onSubmit={onPostContactUs} loading={loadingPost} />
            {modalData.show && (
                <NotifficationModal
                    type={modalData.type}
                    title={modalData.title}
                    message={modalData.message}
                    onClose={() => setModalData({ ...modalData, show: false })}
                />
            )}
            <Header bgColor="bg-[#0C2665]" className="h-screen  md:h-[24rem] relative overflow-hidden max-md:hidden" position="end">
                <div className="h-full ">
                    <div className=" h-full z-30">
                        <div className="text-[#fffff] text-lg font-normal">Let's connect with CRI</div>
                        <div className="text-[#fffff] max-w-xl">Contact Us</div>
                    </div>
                </div>
            </Header>
            <Header bgColor="bg-[#0C2665]" className="h-screen  md:h-[24rem] relative overflow-hidden md:hidden" position="center">
                <div className="h-full ">
                    <div className=" h-full z-30">
                        <div className="text-[#fffff] text-lg font-normal">Let's connect with CRI</div>
                        <div className="text-[#fffff] max-w-xl">Contact Us</div>
                    </div>
                </div>
            </Header>
            <main className="text-lg text-[#616161] py-20 space-y-10 ">
                <section className="md:h-[28.125rem] lg:px-28 px-10 py-10 w-full h-full space-x-10 bg-[#F7FBFE] ">
                    <div className="md:flex w-full h-full justify-center items-start md:space-x-10 max-md:space-y-10 ">
                        <div className="flex-1 h-full  space-y-5">
                            <h1 className="hover:cursor-pointer hover:text-main" onClick={handleOpenMapRemoteOffice}>
                                Remote Office
                            </h1>
                            <iframe
                                className=" max-md:hidden h-full w-full"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.7872697772177!2d106.7739474753123!3d-6.291666193697371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f100559be6b3%3A0xff22a7e71874eb77!2sCliste%20Rekayasa%20Indonesia!5e0!3m2!1sid!2sid!4v1725620383180!5m2!1sid!2sid"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                            />
                            <iframe
                                className="md:hidden h-full w-full"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.7872697772177!2d106.7739474753123!3d-6.291666193697371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f100559be6b3%3A0xff22a7e71874eb77!2sCliste%20Rekayasa%20Indonesia!5e0!3m2!1sid!2sid!4v1725620383180!5m2!1sid!2sid"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                            />
                        </div>
                        <div className="flex-1 h-full w-full space-y-5">
                            <h1 className="hover:cursor-pointer hover:text-main" onClick={handleOpenMapHeadOffice}>
                                Head Office
                            </h1>

                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.1594472784536!2d106.7831575!3d-6.2427068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f11768911619%3A0x70d80dcb6eab1da3!2sGandaria%208%20Office%20Tower!5e0!3m2!1sid!2sid!4v1729653070796!5m2!1sid!2sid"
                                className=" max-md:hidden h-full w-full"
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.1594472784536!2d106.7831575!3d-6.2427068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f11768911619%3A0x70d80dcb6eab1da3!2sGandaria%208%20Office%20Tower!5e0!3m2!1sid!2sid!4v1729653070796!5m2!1sid!2sid"
                                className="md:hidden h-full w-full"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                </section>
                <div className="flex justify-center items-center w-full ">
                    <Button className="text-center" title="Contact Us" onClick={handleOpenModal} status="primary" />
                </div>
            </main>
        </div>
    );
}
