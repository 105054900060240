import axios from "../app/api/Index";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";

const useFetchApiPost = (url) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState("");

    useEffect(() => {
        if (loading === false && message) {
            if (status === 200 || status === 201) {
                toast.success(message);
            } else if (status === 401) {
                window.location.reload();
            } else {
                toast.error(message);
            }
        }
    }, [loading]);

    const fetchApiPost = async (url, newData) => {
        setLoading(true);
        try {
            const response = await axios.post(url, newData);
            if (response.data.status) {
                setMessage(response.data.message);
                setStatus(response.data.status);
                setLoading(false);
                setData(response.data.data);
                console.log("success", url, response);
                return response.data;
            }
        } catch (error) {
            console.log("error", url, error, newData);
            setLoading(false);
            setStatus(error.response.data.status);
            setMessage(error.response.data.message === undefined ? error.response.data.title : error.response.data.message);
            throw error;
        }
    };

    return { status, message, loading, fetchApiPost };
};

export default useFetchApiPost;
