import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import HomePages from "../src/pages/Home/index";
import Services from "../src/pages/Services/index";
import Layout from "./Layout/Layout";
import EngineeringServices from "./container/Services/EngineeringServices";
import SoftwareDevelopment from "./container/Services/SoftwareDevelopment";
import AboutUs from "./pages/AboutUs/index";
import OurInsight from "./pages/OurInsight/index";
import Career from "./pages/Career";
import ContactUs from "./pages/ContactUs";
import ServiceDetail from "./container/Services/ServiceDetail";
import DetailCeo from "./container/AboutUs/DetailCeo";
import ArticleDetail from "./container/OurInsight/Section/ArticleDetail";
import OurVacancies from "./container/Career/OurVacancies/OurVacancies";
import OurVacanciesDetail from "./container/Career/OurVacancies/OurVacanciesDetail";
import ApplyForm from "./container/Career/OurVacancies/ApplyForm";
import PaperDetail from "./container/OurInsight/Section/PaperDetail";
import CompanyProfile from "./container/CompanyProfile/CompanyProfileContainer";
import { useEffect } from "react";
import ScrollToTop from "./components/ScrollToTop";

function App() {
    return (
        <div>
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route path="/" element={<HomePages />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/services/:id" element={<ServiceDetail />} />
                        <Route path="/services/engineering" element={<EngineeringServices />} />
                        <Route path="/services/softwaredevelopment" element={<SoftwareDevelopment />} />
                        <Route path="/aboutus" element={<AboutUs />} />
                        <Route path="/aboutus/ceo-detail" element={<DetailCeo />} />
                        <Route path="/aboutus/companyProfile" element={<CompanyProfile />} />
                        <Route path="/ourInsight" element={<OurInsight />} />
                        <Route path="/ourInsight/article/:id" element={<ArticleDetail />} />
                        <Route path="/ourinsight/paper/:id" element={<PaperDetail />} />
                        <Route path="/career" element={<Career />} />
                        <Route path="/career/OurVacancies" element={<OurVacancies />} />
                        <Route path="/career/OurVacancies/detail/:id" element={<OurVacanciesDetail />} />
                        <Route path="/Ourvacancies/apply/:id" element={<ApplyForm />} />
                        <Route path="/contactus" element={<ContactUs />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
