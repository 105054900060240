import React from "react";
import herobackground1 from "../../assets/Hero/hero-background-1.png";
import herobackground2 from "../../assets/Hero/hero-background-2.png";
import herobackground3 from "../../assets/Hero/hero-background-3.png";


import Carousel from "../../components/Carousel";

const slides = [herobackground1, herobackground2, herobackground3];

const titles = ["Meet the professional reliability engineer in Indonesia", "Reliability Through", "Join us today!"];
const description = [
    "CRI is a company that specializes in providing Reliability Engineering solutions for various industries.",
    "To become an internationally recognized firm which provides effective solutuions and contribute to reliability engineering development.",
    "To become an internationally recognized firm which provides effective solutuions and contribute to reliability engineering development.",
];
const tagline = ["", "", "Engineering today"];

export default function Hero({ className }) {
    return (
        <div className={`${className}`}>
            <Carousel autoSlide={true} titles={titles} description={description} tagline={tagline}>
                {slides.map((s, i) => (
                    <img
                        className=" object-cover w-full h-full 2xl:px-5 xl:rounded-[30px] md:rounded-3xl max-md:rounded-none   "
                        key={i}
                        src={s}
                        alt={`Slide ${i + 1}`}
                    />
                ))}
            </Carousel>
        </div>
    );
}
