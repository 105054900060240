import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Hook untuk mendapatkan parameter dari URL
import { articlePaper } from "../../../Data/data";
import Header from "../../../components/Header";
import { FaArrowLeft } from "react-icons/fa";
// import { Document, Page, pdfjs } from "react-pdf";

import pdfBaru from "../../../assets/seragamCRI.pdf";

import pdf from "../../../assets/dummies.pdf";
import Breadcrumbs from "../../../components/Breadcrumbs";
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function PaperDetail() {
    const { id } = useParams();
    const paper = articlePaper.find((f) => f.id === Number(id));
    const navigate = useNavigate();

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [showPDF, setShowPDF] = useState(false);

    const handleBack = () => {
        navigate(-1);
    };

    const pdfView = () => {
        setShowPDF(true);
    };

    // Fungsi untuk menangani suksesnya pemuatan dokumen
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "Our Insight", path: "/ourInsight" },
        { label: paper.title, path: `/ourInsight/paper/${id}` },
    ];

    return (
        <div>
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="md:h-[6rem] max-md:h-[6rem] xl:px-32 md:px-14 px-10"
            ></Header>
            <Breadcrumbs breadcrumbs={breadcrumb} />

            <div className="relative flex flex-col w-full items-center md:px-24 md:py-20 p-5 font-normal bg-[#F6FBFF] ">
                {paper ? (
                    <div className="w-full text-[#676767] space-y-5 bg-white p-8 shadow-lg drop-shadow-lg md:rounded-xl overflow-y-scroll max-h-[800px]">
                        <div className="header space-y-10  xl:flex xl:items-start h-full">
                            <div className="xl:flex-1">
                                <div className="font-normal space-y-5 text">
                                    <div>{paper.title && <h1 className="md:text-3xl font-semibold">{paper.title}</h1>}</div>
                                    <div className="text-[#9E9E9E] max-md:text-xs">
                                        {paper.date && <p>{paper.date}</p>}
                                        {paper.title && <p>{paper.title}</p>}
                                        {paper.readTime && <p>{paper.readTime}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="xl:w-1/4 xl:h-full font-semibold space-x-5 space-y-4 max-md:text-xs flex justify-end">
                                {/* <button
                                    className="text-[#003478] bg-white border border-[#003478] max-sm:px-3 px-4 py-2 ease-in-out duration-300 hover:scale-105"
                                    onClick={pdfView}
                                >
                                    Read Full Text
                                </button> */}
                                <a
                                    href={pdf}
                                    className="bg-[#003478] text-white px-4 py-2 max-sm:px-3 max-md:w-full  ease-in-out duration-300 hover:scale-105 text-center"
                                    target="_blank" // Buka di tab baru
                                    rel="noopener noreferrer"
                                >
                                    Download
                                </a>
                            </div>
                        </div>

                        <div className="w-full">
                            <h1 className="font-semibold">Abstract</h1>
                            <p>{paper.abstract}</p>
                        </div>
                        {showPDF && (
                            <div className="w-full mt-8 ">
                                <div className="flex justify-between mt-4">
                                    <button
                                        onClick={() => setPageNumber((prevPage) => Math.max(prevPage - 1, 1))}
                                        disabled={pageNumber <= 1}
                                        className="px-4 py-2 border border-gray-300 bg-gray-100 disabled:opacity-50"
                                    >
                                        Previous
                                    </button>
                                    <p>
                                        Page {pageNumber} of {numPages}
                                    </p>
                                    <button
                                        onClick={() => setPageNumber((prevPage) => Math.min(prevPage + 1, numPages || 1))}
                                        disabled={pageNumber >= (numPages || 1)}
                                        className="px-4 py-2 border border-gray-300 bg-gray-100 disabled:opacity-50"
                                    >
                                        Next
                                    </button>
                                </div>
                                {/* <Document className={"flex justify-center items-center "} file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page width={600} height={800} pageNumber={pageNumber} />
                                </Document> */}
                                <div className="flex justify-between mt-4">
                                    <button
                                        onClick={() => setPageNumber((prevPage) => Math.max(prevPage - 1, 1))}
                                        disabled={pageNumber <= 1}
                                        className="px-4 py-2 border border-gray-300 bg-gray-100 disabled:opacity-50"
                                    >
                                        Previous
                                    </button>
                                    <p>
                                        Page {pageNumber} of {numPages}
                                    </p>
                                    <button
                                        onClick={() => setPageNumber((prevPage) => Math.min(prevPage + 1, numPages || 1))}
                                        disabled={pageNumber >= (numPages || 1)}
                                        className="px-4 py-2 border border-gray-300 bg-gray-100 disabled:opacity-50"
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <p>Paper detail not found.</p>
                )}
            </div>
        </div>
    );
}
