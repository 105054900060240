import bgEngineeringServices from "../assets/servicesPage/OurServices/engineeringServices.png";
import bgSoftwareDevelopment from "../assets/servicesPage/OurServices/softwareDevelopment.png";
import operation from "../assets/servicesPage/OurServices/opeartion.png";
import appliedEngineering from "../assets/servicesPage/OurServices/appliedEngineering.png";
import procurement from "../assets/servicesPage/OurServices/procurement.png";
// industries COntent
import oilAndGas from "../assets/servicesPage/Industries/oilAndGas.png";
import Transportation from "../assets/servicesPage/Industries/transportation.png";
import military from "../assets/servicesPage/Industries/military.png";
import education from "../assets/servicesPage/Industries/education.png";
import powerPlant from "../assets/servicesPage/Industries/powerPlant.png";
import petroChemical from "../assets/servicesPage/Industries/petrochemical.png";

import pakChandra from "../assets/fotoWebsite/kohchan.png";
import pakYoyok from "../assets/fotoWebsite/pakyok.png";
import pakTeguh from "../assets/fotoWebsite/pakteguh.png";
import pakDidik from "../assets/fotoWebsite/pakdidik.png";
import habil from "../assets/fotoWebsite/habil.png";
import irfan from "../assets/fotoWebsite/irfan.png";

import cardItem1 from "../assets/cardItem/cardItem1.jpeg";
import cardItem2 from "../assets/cardItem/cardItem2.jpeg";
import cardItem3 from "../assets/cardItem/cardItem3.jpeg";

import conocoPhilips from "../assets/clients/conocoPhilips.svg";
import spie from "../assets/clients/spie.svg";
import pertamina from "../assets/clients/pertamina.svg";
import adhi from "../assets/clients/adhi.png";
import astrafinancial from "../assets/clients/astrafinancial.png";
import faro from "../assets/clients/faro.png";
import harbour from "../assets/clients/harbour.png";
import kemenhan from "../assets/clients/kemenhan.png";
import kementrianPerhubungan from "../assets/clients/kementrianPerhubungan.png";
import kementriankeuangan from "../assets/clients/kementriankeuangan.png";
import korosiSpecindo from "../assets/clients/korosiSpecindo.png";
import lapiItb from "../assets/clients/lapiItb.png";
import medcoEnergi from "../assets/clients/medcoEnergi.png";
import petrocina from "../assets/clients/petrocina.png";
import petronas from "../assets/clients/petronas.png";
import pgn from "../assets/clients/pgn.png";
import pjb from "../assets/clients/pjb.png";
import pln from "../assets/clients/pln.png";
import reliasoft from "../assets/clients/reliasoft.png";
import startenergy from "../assets/clients/starEnergy.png";
import tniAu from "../assets/clients/tniAU.png";
import unihsyiahkuala from "../assets/clients/unihsyiahkuala.png";

import chandra from "../assets/chandra.png";
import CEOBanner from "../assets/aboutusPage/detailCEOBanner.png";

import smallArticle from "../assets/smallArticle.png";

import pdf from "../assets/dummies.pdf";


// userID :
// 726a0e61-9fde-409e-a83a-8fac73850434


export const services = [
    {
        id: 1,
        description: "Basic Engineering, Front End Engineering Design (FEED), Detail Engineering Design (DED), Inspection, Training.",
        title: "Engineering Services",
        image: bgEngineeringServices,
        content: [
            {
                title: "Services group:",
                subTitle: [
                    {
                        content: "Basic Engineering, Front End Engineering Design (FEED), Detail Engineering Design (DED), Inspection, Training.",
                    },
                ],
            },
            {
                title: "Deliverables",
                subContent: [
                    {
                        content: "Asset Register",
                    },
                    {
                        content: "Bill of Material (BOM)",
                    },

                    {
                        content: "Material Cataloguing",
                    },
                    {
                        content: " Process Safety",
                    },
                    {
                        content: "Reliability Availability Maintainability (RAM)",
                    },
                    {
                        content: "Risk Based Inspection (RBI)",
                    },
                    {
                        content: " Reliability Centered Maintenance (RCM)",
                    },
                    {
                        content: "Safety Critical Element (SCE)",
                    },
                    {
                        content: "Equipment Criticality Analysis (ECA) Quantitatively / Qualitatively",
                    },
                    {
                        content: "Pre Activity Safety Review (PASR) Reliability & Asset Management Training.",
                    },
                ],
            },
        ],
    },
    {
        id: 2,
        description:
            "Asset Integrity Management (AIM), Operation Readiness Strategy Assurance (ORSA), Corrosion Management Plan (CMP), Process Safety Management (PSM).",
        title: "Operation",
        image: operation,
        content: [
            {
                title: "Services group:",
                subTitle: [
                    {
                        content:
                            "Asset Integrity Management (AIM), Operation Readiness Strategy Assurance (ORSA), Corrosion Management Plan (CMP), Process Safety Management (PSM).",
                    },
                ],
            },
            {
                title: "Deliverables",
                subContent: [
                    {
                        content: "Asset Register",
                    },
                    {
                        content: "Bill of Material (BOM)",
                    },
                    {
                        content: "Material Cataloguing",
                    },
                    {
                        content: "Process Safety",
                    },
                    {
                        content: "Reliability Availability Maintainability (RAM)",
                    },
                    {
                        content: "Risk Based Inspection (RBI)",
                    },
                    {
                        content: "Reliability Centered Maintenance (RCM)",
                    },
                    {
                        content: "Safety Critical Element (SCE)",
                    },
                    {
                        content: "Equipment Criticality Analysis (ECA) Quantitatively / Qualitatively",
                    },
                    {
                        content: "Pre Activity Safety Review (PASR) Reliability & Asset Management Training.",
                    },
                ],
            },
        ],
    },
    {
        id: 3,
        description: "New Development, Reverse Engineering, Service/Repair, Modify, Upgrade.",
        title: "Applied Engineering",
        image: appliedEngineering,
        content: [
            {
                title: "Services group:",
                subTitle: [
                    {
                        content: "New Development, Reverse Engineering, Service/Repair, Modify, Upgrade.",
                    },
                ],
            },
            {
                title: "Deliverables",
                subContent: [
                    {
                        content: "Automatic Weather Observing System (AWOS)",
                    },
                    {
                        content: "Control & Communication System Surveillance Radar",
                    },
                    {
                        content: "GTA Radio",
                    },
                    {
                        content: "Simulator",
                    },
                    {
                        content: "Weather Radar",
                    },
                    {
                        content: "Power Supply System",
                    },
                    {
                        content: "Megger",
                    },
                    {
                        content: "Turbine",
                    },
                    {
                        content: "Compressor",
                    },
                    {
                        content: "Electronics",
                    },
                ],
            },
        ],
    },

    {
        id: 4,
        description: "Aircraft Spare Parts, Industrial Equipment, Instrument, Tools.",
        title: "Procurement",
        image: procurement,
        content: [
            {
                title: "Services group:",
                subTitle: [
                    {
                        content: "Aircraft Spare Parts, Industrial Equipment, Instrument, Tools.",
                    },
                ],
            },
            {
                title: "Deliverables",
                subContent: [
                    {
                        content: "Boeing 737 Series",
                    },
                    {
                        content: "SuperTucano",
                    },
                    {
                        content: "F16",
                    },
                    {
                        content: "Hercules C130",
                    },
                    {
                        content: "Ground Support Equipment",
                    },
                    {
                        content: "Metal 3D Scanner & Printer",
                    },
                    {
                        content: "Valve",
                    },
                    {
                        content: "Thermostat",
                    },
                    {
                        content: "Relay",
                    },
                    {
                        content: "Digital Manifold",
                    },
                    {
                        content: "Gauge",
                    },
                    {
                        content: "Switch",
                    },
                    {
                        content: "Stabilizer",
                    },
                    {
                        content: "Customized Cable",
                    },
                    {
                        content: "Cable Gland",
                    },
                    {
                        content: "Sensor",
                    },
                    {
                        content: "Other specialized components.",
                    },
                ],
            },
        ],
    },
    {
        id: 5,
        description: "Customized Software.",
        title: "Software Development",
        image: bgSoftwareDevelopment,
        content: [
            {
                title: "Services group:",
                subTitle: [
                    {
                        content: "Customized Software.",
                    },
                ],
            },
            {
                title: "Deliverables",
                subContent: [
                    {
                        content: "Open Source Intelligence",
                    },
                    {
                        content: "Asset Management",
                    },
                    {
                        content: "Safety",
                    },
                    {
                        content: "Reliability",
                    },
                    {
                        content: "Integrity",
                    },
                ],
            },
        ],
    },
];

export const industries = [
    {
        id: 1,
        title: "Oil and Gas",
        image: oilAndGas,
        commitmentStatement:
            "Since 2014, CRI has been a trusted partner to leading names in the Oil & Gas industry, including Pertamina, Premier Oil (Harbour Energy), MEDCO, ConocoPhillips, Star Energy, Geo Dipa Energy, etc.",
        challengesStatement:
            "At CRI, we understand the unique challenges faced by the Oil & Gas sector. Our comprehensive suite of services is designed to address these challenges head-on, ensuring that our clients can operate with confidence.",
        internationalStandards: ["ISO 14224", "ISO 55000", "EN 60812", "SAE JA 1011", "SAE JA 1012", "EN 61078", "API 580", "API 581", "API 691"],
        expertiseAreas: [
            {
                title: "Operation & Maintenance Philosophy",
                description: "We develop robust frameworks that optimize the performance and longevity of your assets.",
            },
            {
                title: "RAM Analysis",
                description:
                    "Our Reliability, Availability, and Maintainability (RAM) analysis helps identify potential bottlenecks and improve system reliability.",
            },
            {
                title: "Tag Numbering, Asset Register & Operations Development",
                description: "We streamline asset management processes to ensure accurate tracking and efficient operations.",
            },
            {
                title: "Equipment Criticality Assessment (ECA)",
                description: "By assessing the criticality of equipment, we help prioritize maintenance efforts and mitigate risks.",
            },
            {
                title: "Failure Modes and Effect Analysis (FMEA)",
                description: "Our FMEA services identify potential failure modes and their impacts, enabling proactive risk management.",
            },
            {
                title: "Maintenance Selection & Task Development (RCM Study)",
                description: "We develop maintenance strategies that are both cost-effective and aligned with operational goals.",
            },
            {
                title: "Risk Based Inspection (RBI) Program",
                description: "Our RBI programs focus on identifying and managing risks associated with equipment failure.",
            },
            {
                title: "Corrosion & Erosion Monitoring Program",
                description: "We implement monitoring programs to detect and address corrosion and erosion issues before they escalate.",
            },
            {
                title: "Spare Parts Management",
                description: "Our spare parts management solutions ensure that critical components are available when needed, minimizing downtime.",
            },
            {
                title: "Function Test Procedure",
                description: "We develop and implement function test procedures to verify the performance and reliability of your systems.",
            },
            {
                title: "Isolation for Production & Maintenance Operations Procedure",
                description: "Our isolation procedures ensure safe and efficient production and maintenance activities.",
            },
            {
                title: "Operation & Production Program",
                description: "We design and implement programs that optimize production processes and enhance operational efficiency.",
            },
            {
                title: "Operations, Maintenance & Training Manuals",
                description: "Our comprehensive manuals provide detailed guidance on operations, maintenance, and training.",
            },
            {
                title: "Assistance to Company Operations Team",
                description:
                    "Our experts provide hands-on assistance to your operations team, ensuring seamless integration and execution of our solutions.",
            },
        ],
        conclusionStatement: "By partnering with CRI, you gain access to a wealth of knowledge and experience that can transform your operations.",
    },
    {
        id: 2,
        title: "Transportation",
        image: Transportation,
        commitmentStatement:
            "Since 2017, CRI has been a trusted partner in the transportation industry, providing top-tier services to Light Rail Transit systems. At CRI, we are committed to supporting our clients’ operations with a full suite of services designed to optimize performance and extend the lifespan of critical assets. ",
        services: [
            { title: "Function test procedures" },
            { title: "Comprehensive training programs" },
            { title: "Development of operations, maintenance, and training manuals" },
            { title: "Hands-on assistance to company operations teams" },
            { title: "Spare part procurement" },
            { title: "New equipment development" },
            { title: "Detailed operation and maintenance philosophies" },
        ],
        conclusionStatement:
            "We pride ourselves on our ability to enhance operational efficiency and reliability through meticulous RAM analysis, asset register development, and spare parts management. By partnering with CRI, clients gain access to a wealth of knowledge and experience, empowering them to achieve their operational goals with confidence and precision.",
    },
    {
        id: 3,
        title: "Military",
        image: military,
        commitmentStatement:
            "CRI is dedicated to enhancing the operational capabilities of military organizations through our comprehensive range of services. Since 2019, we have proudly served the Indonesian Air Force (TNI-AU) and the Ministry of Defense (Kemenhan), delivering exceptional solutions in reliability engineering, spare part procurement, and new equipment development. Our commitment to excellence ensures that we provide the highest quality services, tailored to meet the unique needs of each client",
        expertiseAreas: [
            { title: "Development of Test Benches" },
            {
                title: "Flight Simulator Reparations",
            },
            { title: "Spare Parts Procurement" },
            { title: "RAM Analysis" },
            { title: "Function Test Procedures" },
            { title: "Training and Assistance" },
        ],
        conclusionStatement: "Trust CRI to be your partner in advancing military capabilities through innovative and reliable engineering solutions.",
    },
    {
        id: 4,
        title: "Education",
        image: education,
        commitmentStatement:
            "Since 2014, CRI has been a trusted partner for numerous clients, providing top-notch training services to enhance the skills of their engineers in the field of reliability engineering. Our comprehensive training programs are designed to equip engineers with the knowledge and tools they need to excel in their roles, ensuring the highest standards of reliability and safety in their operations",
        challengesStatement:
            "Our esteemed clients include industry leaders such as Petronas, Pertamina, Star Energy, Indonesia Power, Perusahaan Listrik Negara (PLN), and lecturers from the University of Syiah Kuala. These organizations have benefited from our expertise and continue to rely on us for their training needs.",

        trainingPrograms: [
            {
                title: "Reliability Availability Maintainability (RAM) Analysis",
                description:
                    "This program focuses on the principles and practices of RAM analysis, helping engineers to optimize the reliability and maintainability of their systems.",
            },
            {
                title: "Maintenance & Reliability Excellence (MREX)",
                description:
                    "Aimed at fostering excellence in maintenance and reliability practices, this course covers best practices and strategies for achieving operational excellence.",
            },
            {
                title: "Certified Maintenance & Reliability (CMRP) Exam Preparation",
                description:
                    "We provide comprehensive preparation for the CMRP exam, ensuring that engineers are well-equipped to achieve certification and advance their careers.",
            },
            {
                title: "Root Cause & Failure Analysis (RCFA)",
                description:
                    "This training helps engineers to systematically identify and address the root causes of failures, improving overall system reliability.",
            },
            {
                title: "Hazard Operability (HAZOP) & Hazard Identification (HAZID)",
                description:
                    "Our HAZOP and HAZID training programs are designed to enhance engineers’ ability to identify and mitigate potential hazards, ensuring safer operations.",
            },
        ],
        conclusionStatement:
            "By partnering with CRI, clients gain access to industry-leading training that empowers their engineers to drive reliability and safety improvements within their organizations. We are committed to delivering high-quality training that meets the evolving needs of our clients and supports their journey towards operational excellence.",
    },
    {
        id: 5,
        title: "Power Plant",
        image: powerPlant,
        commitmentStatement:
            "Since 2014, CRI has been at the forefront of reliability engineering, providing expert solutions to a diverse range of clients in the power plant sector. Our extensive experience and commitment to excellence have enabled us to deliver comprehensive consultancy services to prominent organizations such as PJB, Indonesia Power, and Perusahaan Listrik Negara (PLN), among others.",
        servicesProgram: [
            {
                title: "Reliability Availability Maintainability (RAM) Analysis",
                description: "We conduct thorough RAM analyses to optimize the performance and reliability of power plant systems.",
            },
            {
                title: "Root Cause & Failure Analysis (RCFA)",
                description: "Our RCFA services help identify the underlying causes of failures and implement effective corrective actions.",
            },
            {
                title: "Hazard Operability (HAZOP) & Hazard Identification (HAZID)",
                description: "We facilitate HAZOP and HAZID studies to ensure the safety and operability of plant processes.",
            },
            {
                title: "Operation & Maintenance Philosophy",
                description: "We develop tailored operation and maintenance philosophies to align with our clients’ strategic goals.",
            },
            {
                title: "Tag Numbering, Asset Register & Operations Development (KKS System)",
                description: "Our expertise in the KKS system ensures accurate tagging and efficient asset management.",
            },
            {
                title: "Equipment Criticality Assessment (ECA)",
                description: "We perform ECAs to prioritize maintenance efforts based on equipment criticality.",
            },
            {
                title: "Failure Modes and Effect Analysis (FMEA)",
                description: "Our FMEA services identify potential failure modes and their impacts to mitigate risks.",
            },
            {
                title: "Maintenance Selection & Task Development (RCM Study)",
                description: "We conduct RCM studies to develop effective maintenance strategies and tasks.",
            },
            {
                title: "Risk Based Inspection (RBI) Program",
                description: "Our RBI programs focus on risk assessment to optimize inspection schedules and resources.",
            },
            {
                title: "Corrosion & Erosion Monitoring Program",
                description: "We implement monitoring programs to manage and mitigate corrosion and erosion in plant equipment.",
            },
            {
                title: "Spare Parts Management",
                description: "Our spare parts management services ensure the availability of critical components to minimize downtime.",
            },
            {
                title: "Function Test Procedure",
                description: "We develop and implement function test procedures to verify the performance of plant systems.",
            },
            {
                title: "Isolation for Production & Maintenance Operations Procedure",
                description: "Our isolation procedures ensure safe and efficient production and maintenance operations.",
            },
            {
                title: "Operation & Production Program",
                description: "We design and implement comprehensive operation and production programs to enhance plant efficiency.",
            },
            {
                title: "Operations, Maintenance & Training Manuals",
                description: "We provide detailed manuals to support the ongoing operations and maintenance of power plants.",
            },
            {
                title: "Assistance to Company Operations Team",
                description: "Our experts offer hands-on assistance to company operations teams, ensuring smooth and efficient plant operations.",
            },
        ],
        conclusionStatement:
            "At CRI, we are dedicated to empowering our clients with the knowledge and tools they need to achieve operational excellence and reliability in their power plant operations. Our tailored solutions and expert guidance help our clients navigate the complexities of the industry and achieve their strategic objectives.",
    },
    {
        id: 6,
        title: "Petrochemical",
        image: petroChemical,
        commitmentStatement: "Since 2014, CRI has been at the forefront of reliability engineering, adhering to international standards.",
        challengesStatement: "Our extensive experience and commitment to these standards ensure that we deliver top-notch services.",
        services: [
            {
                title: "Reliability Availability Maintainability (RAM) Analysis",
                description:
                    "We conduct thorough RAM analyses to optimize the performance and reliability of your systems, ensuring minimal downtime and maximum efficiency.",
            },
            {
                title: "Root Cause & Failure Analysis (RCFA)",
                description:
                    "Our RCFA services help identify the underlying causes of failures, enabling you to implement effective corrective actions and prevent recurrence.",
            },
            {
                title: "Hazard Operability (HAZOP) & Hazard Identification (HAZID)",
                description:
                    "We perform detailed HAZOP and HAZID studies to identify potential hazards and operability issues, ensuring the safety and reliability of your processes.",
            },
            {
                title: "Operation & Maintenance Philosophy",
                description:
                    "We develop comprehensive operation and maintenance philosophies that align with your business objectives and industry best practices.",
            },
            {
                title: "Tag Numbering, Asset Register & Operations Development (KKS System)",
                description:
                    "Our team assists in the development of structured tag numbering systems, asset registers, and operations development using the KKS system.",
            },
            {
                title: "Equipment Criticality Assessment (ECA)",
                description:
                    "We conduct ECAs to prioritize equipment based on their criticality to your operations, ensuring focused maintenance efforts.",
            },
            {
                title: "Failure Modes and Effect Analysis (FMEA)",
                description:
                    "Our FMEA services help identify potential failure modes and their effects, allowing you to implement proactive measures to mitigate risks.",
            },
            {
                title: "Risk Based Inspection (RBI) Program",
                description:
                    "Our RBI programs are designed to optimize inspection efforts based on risk assessments, ensuring the integrity and safety of your assets.",
            },
            // You can add more services here if needed
        ],
        conclusionStatement:
            "Choosing CRI means partnering with a team of experts dedicated to enhancing the reliability and efficiency of your petrochemical operations. Our commitment to international standards and industry best practices ensures that we deliver solutions that are not only effective but also sustainable. Let us help you achieve operational excellence and drive your business forward.",
    },
];

export const teamMembers = [
    {
        name: "Ir. Chandra Salim, M.Sc. IPM, ASEAN Eng",
        position: "Director",
        imageSrc: pakChandra,
    },
    {
        name: "Suprayoga",
        position: "Commisioner",
        imageSrc: pakYoyok,
    },
    {
        name: "Didik Pramuji, CFSP",
        position: "Engineering Manager",
        imageSrc: pakDidik,
    },
    {
        name: "Teguh Jumadi",
        position: "General Affair",
        imageSrc: pakTeguh,
    },
    {
        name: "Habil Tria Sakti",
        position: "ICT Manager",
        imageSrc: habil,
    },
    {
        name: "Irfan Septian",
        position: "Financial Manager",
        imageSrc: irfan,
    },
];
export const articles = [
    {
        id: 1,
        imgSrc: smallArticle,
        title: "Why is Software Development Important?",
        date: "28 June 2024",
        timeRead: "5 min read",
        summary: [
            "Lorem ipsum odor amet, consectetuer adipiscing elit. Dictum eros elementum conubia bibendum neque, fringilla posuere ad habitasse. Curae litora purus pretium turpis fusce porttitor. Netus placerat eleifend libero habitasse ad natoque. Nullam pretium dictum imperdiet vehicula urna fusce ante non. Pellentesque sapien praesent quisque magna odio. Commodo nostra aliquam ac efficitur nisl ornare dolor eros. Fringilla mattis enim elit sapien lacinia viverra odio. Semper sollicitudin justo, quisque a efficitur nibh. Placerat mi euismod himenaeos ipsum hac montes. Elementum erat porta dictum gravida mollis tempor praesent mattis. Mattis augue morbi praesent ut posuere aliquet nam vulputate sit. Lacus faucibus metus; nisi pulvinar rhoncus eleifend. Cursus ipsum egestas urna diam placerat.",
            "Lorem ipsum odor amet, consectetuer adipiscing elit. Dictum eros elementum conubia bibendum neque, fringilla posuere ad habitasse. Curae litora purus pretium turpis fusce porttitor. Netus placerat eleifend libero habitasse ad natoque. Nullam pretium dictum imperdiet vehicula urna fusce ante non. Pellentesque sapien praesent quisque magna odio. Commodo nostra aliquam ac efficitur nisl ornare dolor eros. Fringilla mattis enim elit sapien lacinia viverra odio. Semper sollicitudin justo, quisque a efficitur nibh. Placerat mi euismod himenaeos ipsum hac montes. Elementum erat porta dictum gravida mollis tempor praesent mattis. Mattis augue morbi praesent ut posuere aliquet nam vulputate sit. Lacus faucibus metus; nisi pulvinar rhoncus eleifend. Cursus ipsum egestas urna diam placerat.",
        ],
        author: "by Boakay Eddie Foday",
    },
    {
        id: 2,
        imgSrc: smallArticle,
        title: "Compliant Global Content Translation",
        date: "Pertamina Indonesia (2023)",
        timeRead: "4 min read",
        summary:
            "Effective translation services are essential for global businesses. This article explores compliance in translation practices and its impact on international operations.",
        author: "by John Smith",
    },
    {
        id: 3,
        imgSrc: smallArticle,
        title: "The Role of Agile Methodologies in Software Development",
        date: "15 July 2024",
        timeRead: "6 min read",
        summary:
            "Agile methodologies have transformed the software development landscape, allowing teams to be more adaptive and customer-focused, ultimately leading to better product outcomes.",
        author: "by Sarah Johnson",
    },
    {
        id: 4,
        imgSrc: smallArticle,
        title: "Software Development Best Practices",
        date: "22 July 2024",
        timeRead: "5 min read",
        summary:
            "This article outlines best practices in software development, including version control, continuous integration, and effective testing strategies that enhance code quality.",
        author: "by David Lee",
    },
    {
        id: 5,
        imgSrc: smallArticle,
        title: "Asset Integrity & Reliability Management System: Phase 1 Overview",
        date: "28 June 2024",
        timeRead: "5 min read",
        summary:
            "Phase 1 of our Asset Integrity Management System focuses on establishing a robust asset register and implementing spare part management for operational efficiency.",
        author: "by Emily White",
    },
    {
        id: 6,
        imgSrc: smallArticle,
        title: "Exploring the Future of Software Development with AI",
        date: "10 August 2024",
        timeRead: "7 min read",
        summary:
            "Artificial Intelligence is poised to reshape the software development landscape. This article delves into emerging AI tools that enhance coding efficiency and software quality.",
        author: "by Michael Brown",
    },
];
export const articlePaper = [
    {
        id: 1,
        src: cardItem1,
        date: "28 June 2024",
        title: "Why is Software Development Important?",
        readTime: "5 min read",
        isbn: "978-3-16-148410-0", // Dummy ISBN
        tags: ["Software Development", "Technology", "Business"], // Dummy tags
        abstract: `Software development plays a critical role in modern businesses, enabling companies to automate processes, 
        improve efficiency, and foster innovation. The importance of software has grown exponentially as industries across the globe 
        rely on it for various operations, from communication to project management. In this paper, we explore the key reasons why 
        investing in software development can offer a competitive advantage. It also highlights the trends in software engineering, 
        best practices, and common challenges faced by developers and businesses alike.`,
        pdfUrl: pdf,
    },
    {
        id: 2,
        src: cardItem2,
        date: "28 June 2024",
        title: "Compliant global content translation",
        readTime: "5 min read",
        isbn: "978-1-234-56789-0", // Dummy ISBN
        tags: ["Content", "Translation", "Globalization"], // Dummy tags
        abstract: `Global content translation is essential for businesses seeking to expand into international markets. This paper 
        focuses on the critical aspects of compliant translation, ensuring that content not only conveys the intended message but also 
        meets the regulatory and cultural requirements of different regions. The paper further delves into the technology that aids 
        global content translation, including AI-driven tools, and discusses the importance of localization and maintaining brand voice 
        across languages and platforms, ensuring effective communication worldwide.`,
        pdfUrl: "https://online-journal.unja.ac.id/JurnalEngineering/article/view/23700/17607", // URL PDF The GNU Manifesto
    },
    {
        id: 3,
        src: cardItem3,
        date: "28 June 2024",
        title: "Why is Software Development Important?",
        readTime: "5 min read",
        isbn: "978-0-123-45678-9", // Dummy ISBN
        tags: ["Software Development", "Industry", "Trends"], // Dummy tags
        abstract: `The role of software development in modern industries cannot be overstated. It has become a backbone for innovation, 
        enabling companies to enhance their operations, meet customer needs, and adapt to market changes. This paper explores how 
        software development methodologies have evolved, the impact of agile and DevOps practices on the speed and quality of 
        software delivery, and the essential skills developers need to thrive in a competitive and rapidly changing landscape. 
        Additionally, we address the future of software development in the context of AI and automation.`,
        pdfUrl: "https://online-journal.unja.ac.id/JurnalEngineering/article/view/23700/17607", // URL PDF The GNU Manifesto
    },
];

export const infoCardData = [
    {
        title: "10",
        subtitle: "Years",
        description: "Experience",
    },
    {
        title: "120+",
        subtitle: "Projects",
        description: "Successful",
    },
    {
        title: "20+",
        subtitle: "Clients",
        description: "That we help",
    },
];

export const projects = {
    Engineering: [
        {
            imgSrc: spie,
            title: "Software: Asset Integrity & Reliability Management System Phase 1: Asset Register, Spare Part Management, and Bill of Material",
            description: "SPIE Oil and Gas Thailand (North Oil Company - Qatar) (2023)",
        },
        {
            imgSrc: pertamina,
            title: "Software: Asset Integrity & Reliability Management System Phase 1: Asset Register, Spare Part Management, and Bill of Material",
            description: "Pertamina Indonesia (2023)",
        },
        {
            imgSrc: spie,
            title: "Software: Asset Integrity & Reliability Management System Phase 1: Asset Register, Spare Part Management, and Bill of Material",
            description: "SPIE Oil and Gas Thailand (North Oil Company - Qatar) (2023)",
        },
        {
            imgSrc: spie,
            title: "Software: Asset Integrity & Reliability Management System Phase 1: Asset Register, Spare Part Management, and Bill of Material",
            description: "SPIE Oil and Gas Thailand (North Oil Company - Qatar) (2023)",
        },
        {
            imgSrc: spie,
            title: "Software: Asset Integrity & Reliability Management System Phase 1: Asset Register, Spare Part Management, and Bill of Material",
            description: "SPIE Oil and Gas Thailand (North Oil Company - Qatar) (2023)",
        },
        {
            imgSrc: pertamina,
            title: "Software: Asset Integrity & Reliability Management System Phase 1: Asset Register, Spare Part Management, and Bill of Material",
            description: "Pertamina Indonesia (2023)",
        },
        {
            imgSrc: spie,
            title: "Software: Asset Integrity & Reliability Management System Phase 1: Asset Register, Spare Part Management, and Bill of Material",
            description: "SPIE Oil and Gas Thailand (North Oil Company - Qatar) (2023)",
        },
        {
            imgSrc: spie,
            title: "Software: Asset Integrity & Reliability Management System Phase 1: Asset Register, Spare Part Management, and Bill of Material",
            description: "SPIE Oil and Gas Thailand (North Oil Company - Qatar) (2023)",
        },
        {
            imgSrc: spie,
            title: "Software: Asset Integrity & Reliability Management System Phase 1: Asset Register, Spare Part Management, and Bill of Material",
            description: "SPIE Oil and Gas Thailand (North Oil Company - Qatar) (2023)",
        },
        {
            imgSrc: pertamina,
            title: "Software: Asset Integrity & Reliability Management System Phase 1: Asset Register, Spare Part Management, and Bill of Material",
            description: "Pertamina Indonesia (2023)",
        },
        {
            imgSrc: spie,
            title: "Software: Asset Integrity & Reliability Management System Phase 1: Asset Register, Spare Part Management, and Bill of Material",
            description: "SPIE Oil and Gas Thailand (North Oil Company - Qatar) (2023)",
        },
        {
            imgSrc: spie,
            title: "Software: Asset Integrity & Reliability Management System Phase 1: Asset Register, Spare Part Management, and Bill of Material",
            description: "SPIE Oil and Gas Thailand (North Oil Company - Qatar) (2023)",
        },
    ],
    Procurement: [
        {
            imgSrc: spie,
            title: "Procurement of Industrial Equipment for Power Plant",
            description: "Supplying critical components for power plant maintenance in Malaysia (2022)",
        },
        {
            imgSrc: spie,
            title: "Procurement of Spare Parts for Oil and Gas Industry",
            description: "Providing spare parts and equipment for offshore rigs in Indonesia (2023)",
        },
        {
            imgSrc: spie,
            title: "Sourcing and Procurement for Renewable Energy Projects",
            description: "Supporting renewable energy development with high-quality materials (2023)",
        },
    ],
    Training: [
        {
            imgSrc: spie,
            title: "Reliability and Asset Management Training",
            description: "Comprehensive training for asset managers in Thailand (2023)",
        },
        {
            imgSrc: spie,
            title: "Safety and Risk-Based Inspection Training",
            description: "Providing in-depth knowledge on RBI techniques to oil and gas companies (2023)",
        },
        {
            imgSrc: spie,
            title: "Customized Software Training for Asset Integrity Management",
            description: "Training on using AIM software for better asset integrity management (2023)",
        },
    ],
};

export const listClients = [
    {
        imageSrc: spie,
    },
    {
        imageSrc: pertamina,
    },
    {
        imageSrc: conocoPhilips,
    },
    {
        imageSrc: tniAu,
    },
    {
        imageSrc: medcoEnergi,
    },
    {
        imageSrc: petronas,
    },
    {
        imageSrc: lapiItb,
    },
    {
        imageSrc: pln,
    },
    {
        imageSrc: faro,
    },
    {
        imageSrc: kemenhan,
    },
    {
        imageSrc: reliasoft,
    },
    {
        imageSrc: adhi,
    },
    {
        imageSrc: startenergy,
    },
    {
        imageSrc: pjb,
    },
    {
        imageSrc: pgn,
    },
    {
        imageSrc: petrocina,
    },
    {
        imageSrc: astrafinancial,
    },
    {
        imageSrc: unihsyiahkuala,
    },
    {
        imageSrc: harbour,
    },
    {
        imageSrc: korosiSpecindo,
    },
    {
        imageSrc: kementriankeuangan,
    },
    {
        imageSrc: kementrianPerhubungan,
    },
];

export const CEO_DETAILS = {
    name: "Chandra Salim",
    role: "CEO",
    summary: [
        "Mr. Chandra Salim is a seasoned Maintenance & Reliability Professional with extensive experience in project development, commissioning, operation, and maintenance. As the CEO of CRI, Mr. Chandra brings a wealth of knowledge and expertise to the table, ensuring the highest standards of reliability and safety in all operations.",
        "Specializing in Failure Modes and Effects Analysis (FMEA), Root Cause Failure Analysis (RCFA), Reliability, Availability, and Maintainability (RAM), Reliability-Centered Maintenance (RCM), and comprehensive Reliability & Safety Analysis, Mr. Chandra is dedicated to enhancing the visibility of Maintenance & Reliability Data. Under Mr. Chandra's leadership, CRI has implemented robust programs for Overall Equipment/Machinery Healthiness and Maintenance Strategy, driving operational excellence and efficiency",
        "With a strong focus on Safety, Reliability, Availability, Maintainability, Spare Part Management, Life Cycle Cost, Project Management, and Operation Management, Mr. Chandra is committed to fostering a culture of continuous improvement and innovation at CRI. Mr. Chandra's strategic vision and hands-on approach ensure that CRI remains at the forefront of the industry, delivering exceptional value to clients and stakeholders alike.",
    ],
    workExamples: [
        "Asset Reliability Specialist for RAM Analysis of Front-End Engineering Design (FEED) Phase for Hidayah Development Project (Petronas) North Madura II PSC, East Java, Indonesia.",
        "Reliability Assessor for Provision of Reliability Engineering Maintenance Services at PT Premier Oil Natuna Sea BV, a Harbour Energy Company.",
        "Asset Integrity Management Specialist (System Analyst) for Asset Management Application Development Phase 1: Asset Register & Spare Part. This customized application is developed by CRI for SPIE Oil & Gas Services Thailand to be used for NOC (Qatar) asset management project.",
        "Reliability Assessor for RAM Analysis for Salak & Darajat Geothermal Power Plant at PT Star Energy Geothermal.",
        "Reliability Assessor for TUV SUD SIL Certification Supervision for Process Transmitter KS-XX1000 series at PT Korosi Specindo.",
    ],
    imgSrc: chandra,
    imgBanner: CEOBanner, // Path ke gambar Chandra
};

export const vacancies = [
    {
        id: 1,
        title: "CRM Specialist",
        level: "Internship",
        department: "Office",
        location: "Onsite - Jakarta",
        workingMode: "Office",
        description:
            "CRM Specialist is Lorem ipsum odor amet, consectetuer adipiscing elit. Sem consequat hendrerit congue accumsan amet purus. Nunc habitasse posuere sollicitudin praesent pulvinar. Potenti suscipit nullam sollicitudin; congue malesuada tempus. Magna vestibulum hac cursus natoque facilisi arcu aliquam sagittis. Sollicitudin luctus. Lorem ipsum odor amet, consectetuer adipiscing elit. Sem consequat hendrerit congue.",
        jobdesk: [
            "Manage and maintain CRM systems",
            "Assist in the implementation of CRM strategies",
            "Analyze customer data to improve satisfaction",
        ],
        requirement: [
            "Currently pursuing a degree in Marketing, IT, or related fields",
            "Familiarity with CRM tools and customer management",
            "Good communication skills",
        ],
        benefit: ["Monthly allowance", "Professional development opportunities", "Mentorship from senior staff"],
    },
    {
        id: 2,
        title: "CMO",
        level: "Fresh Graduate",
        department: "Office",
        location: "WFH",
        workingMode: "Office",
        description: "We are seeking a Fresh Graduate to fill the Chief Marketing Officer (CMO) position, available for remote work (WFH).",
        jobdesk: [
            "Develop and implement marketing strategies",
            "Oversee the company's branding and communication efforts",
            "Collaborate with sales and product teams",
        ],
        requirement: [
            "Bachelor's degree in Marketing, Business, or related fields",
            "Strong understanding of digital marketing trends",
            "Leadership and team management skills",
        ],
        benefit: ["Work from home (WFH) flexibility", "Competitive salary", "Career advancement opportunities"],
    },
    {
        id: 3,
        title: "Reliability Engineer",
        level: "Experienced",
        department: "Engineer",
        location: "Onsite - Jakarta",
        workingMode: "Engineer",
        description: "We are looking for an experienced Reliability Engineer to work onsite in Jakarta.",
        jobdesk: [
            "Ensure reliability and performance of engineering systems",
            "Conduct maintenance and troubleshooting",
            "Analyze system data for improvements",
        ],
        requirement: ["3+ years of experience in engineering roles", "Proficiency in reliability analysis tools", "Strong problem-solving skills"],
        benefit: ["Onsite work environment", "Comprehensive health insurance", "Annual performance bonuses"],
    },
    {
        id: 4,
        title: "Back End Developer",
        level: "Fresh Graduate",
        department: "IT",
        location: "WFH",
        workingMode: "IT",
        description: "We are hiring a Fresh Graduate for the Back End Developer role, available for remote work (WFH).",
        jobdesk: [
            "Develop and maintain server-side logic",
            "Collaborate with front-end developers",
            "Optimize applications for maximum speed and scalability",
        ],
        requirement: [
            "Bachelor's degree in Computer Science or related field",
            "Knowledge of programming languages like Java, Python, or Node.js",
            "Understanding of database technologies",
        ],
        benefit: ["Remote work flexibility", "Access to cutting-edge technology", "Opportunities for career growth"],
    },
    {
        id: 5,
        title: "General Affair",
        level: "Internship",
        department: "Office",
        location: "Hybrid",
        workingMode: "Office",
        description: "We are offering an internship position for General Affair with a hybrid working arrangement.",
        jobdesk: [
            "Assist in managing office administrative tasks",
            "Coordinate with different departments for office needs",
            "Handle procurement and inventory management",
        ],
        requirement: [
            "Currently pursuing a degree in Business Administration or related fields",
            "Good organizational and multitasking skills",
            "Basic knowledge of office management systems",
        ],
        benefit: ["Hybrid working environment", "Monthly stipend", "Learning opportunities from multiple departments"],
    },
];

export const applicationForm = {
    fields: [
        { name: "fullName", label: "Full Name", type: "text", placeholder: "Type here" },
        { name: "email", label: "Email", type: "email", placeholder: "Type here" },
        { name: "contactNumber", label: "Contact Number", type: "tel", placeholder: "Type here" },
        { name: "address", label: "Address", type: "text", placeholder: "Type here" },
        { name: "university", label: "University", type: "text", placeholder: "Type here" },
        { name: "major", label: "Major", type: "text", placeholder: "Type here" },
        { name: "availabilityPeriod", label: "Availability Period", type: "text", placeholder: "Type here" },
        {
            name: "source",
            label: "How did you know about opportunities at CRM?",
            type: "select",
            options: ["Social Media", "Job Portal", "University Career Center", "Referral"],
        },
        { name: "cv", label: "Upload your CV (2MB max)", type: "file", acceptedFormats: ["doc", "docx", "pdf"] },
        { name: "transcript", label: "Upload your transcript (2MB max)", type: "file", acceptedFormats: ["doc", "docx", "pdf"] },
        {
            name: "agreement",
            label: "By proceeding, I confirm that I have carefully read and agree to the Terms of Service and Privacy Policy.",
            type: "checkbox",
        },
    ],
    submitButton: { label: "Submit", type: "submit" },
};

export const breadcrumbMap = [
    { path: "/", label: "Home" },
    { path: "/services", label: "Services" },
    { path: "/services/engineering", label: "Engineering Services" },
    { path: "/services/softwaredevelopment", label: "Software Development" },
    { path: "/aboutus", label: "About Us" },
    { path: "/aboutus/ceo-detail", label: "CEO Detail" },
    { path: "/ourInsight", label: "Our Insight" },
    { path: "/career", label: "Career" },
    { path: "/career/OurVacancies", label: "Our Vacancies" },
    { path: "/contactus", label: "Contact Us" },
];

export const getBreadcrumbs = (pathname, data = {}) => {
    const paths = pathname.split("/").filter(Boolean);
    const breadcrumbs = [{ label: "Home", path: "/" }];

    paths.forEach((path, index) => {
        const fullPath = `/${paths.slice(0, index + 1).join("/")}`;
        const breadcrumb = breadcrumbMap.find((crumb) => crumb.path === fullPath);

        if (breadcrumb) {
            breadcrumbs.push({
                ...breadcrumb,
                label: breadcrumb.label || data[breadcrumb.path]?.title || "Detail",
            });
        }
    });

    return breadcrumbs;
};
