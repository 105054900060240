import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        globalToken: "",
        globalUser: {},
        open: false,
    },
    reducers: {
        setToken: (state, action) => {
            // const { token } = action.payload;
            state.globalToken = action.payload;
        },
        setGlobalUser: (state, action) => {
            state.globalUser = {...action.payload};
        },
        setLogout: (state, action) => {
            state.globalToken = "";
            state.globalUser = {};
            
        },
        setOpen: (state, action) => {
            const { open } = action.payload;
            state.open = open;
        },
    },
});

export const { setToken, setGlobalUser, setLogout, cancelSidebar } = authSlice.actions;
export default authSlice.reducer;
export const selectCurrentToken = (state) => {
    return state.auth.globalToken;
};
export const selectCurrentUser = (state) => {
    return state.auth.globalUser;
};
export const selectOpenSidebar = (state) => {
    return state.auth.open;
}